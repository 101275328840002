import { apiConfig } from '../config/backend-api';
import { adminService } from './admin.service';
import axios from 'axios';

export const stripeService = {
  createSubscription,
  getPlans,
  cancelSubscription,
  getAllForAccount,
  getAllForPayment,
  findCustomer,
  attachPaymentMethod,
  detachPaymentMethod,
  setDefaultPaymentMethod,
  updateBillingInformation,
  updateAccountInformation,
  //hasSubscription,
  getCheckoutSession,
  getCustomerPortalSession
};

// function hasSubscription() {
//   return axios.get(`${apiConfig.baseUrl}/subscriptions/hassubscription/`, { headers: adminService.headerWithToken() });
// }

function cancelSubscription(subscriptionId, opts = {}) {
  return axios.put(`${apiConfig.baseUrl}/subscriptions/${subscriptionId}`, opts, { headers: adminService.headerWithToken() });
}

function updateBillingInformation(billingInformation) {
  return axios.put(`${apiConfig.baseUrl}/subscriptions/billinginformation/`, billingInformation, { headers: adminService.headerWithToken() });
}

function updateAccountInformation(accountInformation) {
  return axios.put(`${apiConfig.baseUrl}/account/`, accountInformation, { headers: adminService.headerWithToken() });
}

function attachPaymentMethod(paymentMethodId, setPaymentMethodAsDefault) {
  return axios.put(
    `${apiConfig.baseUrl}/account/paymentmethods/attach/`,
    { paymentMethodId: paymentMethodId, setPaymentMethodAsDefault: setPaymentMethodAsDefault },
    { headers: adminService.headerWithToken() }
  );
}

function setDefaultPaymentMethod(paymentMethodId) {
  return axios.put(`${apiConfig.baseUrl}/account/paymentmethods/setasdefault/`, { paymentMethodId: paymentMethodId }, { headers: adminService.headerWithToken() });
}

function detachPaymentMethod(paymentMethodId) {
  return axios.delete(`${apiConfig.baseUrl}/account/paymentmethods/detach/${paymentMethodId}`, { headers: adminService.headerWithToken() });
}

function getPlans() {
  return axios.get(`${apiConfig.baseUrl}/subscriptions/plans/`, { headers: adminService.headerWithToken() });
}

function findCustomer() {
  return axios.get(`${apiConfig.baseUrl}/account/customers/`, { headers: adminService.headerWithToken() })
}

function getAllForAccount() {
  return axios.get(`${apiConfig.baseUrl}/subscriptions/account/allinformation/`, { headers: adminService.headerWithToken() });
}

function getAllForPayment() {
  return axios.get(`${apiConfig.baseUrl}/subscriptions/payment/allinformation/`, { headers: adminService.headerWithToken() });
}

function createSubscription(subscription) {
  return axios.post(`${apiConfig.baseUrl}/subscriptions/`, { subscription: subscription }, { headers: adminService.headerWithToken() });
}

function getCheckoutSession({ planid = null, interval = "monthly", success_url = null, cancel_url = null, quantity = 1 }) {
  return axios.post(`${apiConfig.baseUrl}/subscriptions/portal/checkout/`, { planid, interval, success_url, cancel_url, quantity }, { headers: adminService.headerWithToken() });
}

function getCustomerPortalSession({ return_url = "" }) {
  return axios.post(`${apiConfig.baseUrl}/subscriptions/portal/customer/`, { return_url }, { headers: adminService.headerWithToken() });
}