import { createI18n } from 'vue-i18n/index'

const DEFAULT_LANG = "en";

const locales = {
  es: require("./i18n/es.json"),
  en: require("./i18n/en.json")
};

const i18n = createI18n({
  locale: DEFAULT_LANG,
  fallbackLocale: DEFAULT_LANG,
  messages: locales
});

export default i18n;
