//import { apiConfig } from '../config/backend-api';
import axios from 'axios';
//import { store } from '../store';

const wizardUrl = `http://127.0.0.1`;
const wizardPort = 23432;
export const wizardService = {
    ping,
    start,
    login,
    waitFor
};

function ping() {
    return axios.get(`${wizardUrl}:${wizardPort}/ping`, {
      networkError: false,
      ajaxState: false,
    });
}

function start() {
    return axios.get(`${wizardUrl}:${wizardPort}/start`, {
      networkError: false,
      ajaxState: false,
    });
}

function login({ otpl }) {
    return axios.post(`${wizardUrl}:${wizardPort}/login`, {
        'otpl': otpl
    }, {
        networkError: false,
        ajaxState: false,
    });
}

function waitFor(config = {}){
    return axios.get(`${wizardUrl}:${wizardPort}/waitfor`, {
      networkError: false,
      ajaxState: false,
      ...config
    });
}