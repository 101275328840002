<template>
  <Dialog :modal="true" v-model:visible="internal.show">
    <template #header>
      <slot name="header"></slot>
    </template>
    <slot name="content"></slot>
    <template #footer>
      <slot v-bind:emit="emitResponse" name="footer"></slot>
    </template>
  </Dialog>
</template>

<script>
import DialogMixin from '../mixins/DialogMixin';
export default {
  name : 'CustomDialog',
  mixins: [DialogMixin]
}
</script>