import sanitizeHtml from 'sanitize-html';

export const SecurityHelper = {
  sanitizeBlackList: '',
  sanitizeTagBlackList: [],
  validationLengths: [],
  sanitizeString,
  getPropertyLength
};

function sanitizeString(value) {
  let valueSanitized = value;
  if (value) {
    var regex = new RegExp(SecurityHelper.sanitizeBlackList, 'g');
    valueSanitized = value.replace(regex, '');
    SecurityHelper.sanitizeTagBlackList.forEach((element) => {
      valueSanitized = valueSanitized.replace(element, '');
    });
    valueSanitized = sanitizeHtml(valueSanitized, {
      allowedTags: [],
      allowedAttributes: {},
      allowedIframeHostnames: [],
    });
  }
  return valueSanitized;
}

function getPropertyLength(key, propertyName, forInputTag = false) {
  let result = { minLength: 0, maxLength: 500 };
  let sourceFound = SecurityHelper.validationLengths.find(
    (element) => element.source.toLowerCase() === key.toLowerCase()
  );
  if (sourceFound) {
    let propertyFound = sourceFound.properties.find(
      (element) => element.name.toLowerCase() === propertyName.toLowerCase()
    );
    if (propertyFound) {
      result.minLength = propertyFound.min;
      result.maxLength = propertyFound.max;
    }
  }
  if (forInputTag) {
    return result.maxLength > 0 ? result.maxLength : '';
  } else return result;
}
