<template>
    <div class="plan-card-features" v-if="showFeatures">
        <div class="header pricing"  :class="{ 'with-price': pricePlace.toLowerCase() != 'footer' }">
            <double-toggle v-if="includeSelector" v-model="period"/>
        </div>
        <div class="features" v-if="showFeatures">
            <div class="feature" v-for="range in pRanges" :key="range"><b>{{range}}</b></div>
            <div class="feature"><b class="range">{{pSupport}}</b></div>
            <div class="feature" v-for="feat in pFeatures" :key="feat" v-tooltip="feat.help"><span>{{feat.desc}}</span></div>
        </div>
        <div class="footer">&nbsp;</div>
    </div>
</template>
<script>
import PlancardHelper from "../../helpers/PlancardHelper";
import DoubleToggle from '../DoubleToggle.vue';

export default {
    name: "PlanCardFeatures",
    tag: "plan-card-features",
    props: {
        "modelValue": { 'default': true },
        "showFeatures": { 'default': true },
        "includeSelector": { 'default': false },
        'pricePlace': { "default": "header"}
    },
    emits: ["update:modelValue"],
    components: { DoubleToggle },
    data() {
        return {
            periodOptions: [ {"name": this.$t("plans.period.monthly"), "value": true}, {"name": this.$t("plans.period.yearly"), "value": false }],
            pRanges: PlancardHelper.getRangesDesc(this.$t),
            pFeatures: PlancardHelper.getFeaturesDesc(this.$t),
            pSupport: PlancardHelper.getSupportDesc(this.$t),
            period: this.modelValue
        }
    },
    watch: {
        modelValue: function(val) { this.period = val; },
        period: function(val) { this.$emit("update:modelValue",  val); }
    }

}
</script>