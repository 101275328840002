<template>
<div v-if="available" v-tooltip.right="!availableInPlan ? $t('configureService.notAvailableInPlan') : ''"
  :class="{'not-available-feature': !availableInPlan}" class="col-12 p-grid p-mt-0 p-mb-0 p-pl-2 p-pr-2">
  <div v-if="!grayed" class="p-col-10 p-field">
    {{ title }}
  </div>
  <div v-else class="p-col p-field">
    <label>{{title}}</label>
  </div>
  <div class="p-col p-field p-text-right">
    <InputSwitch :disabled="(!availableInPlan || disabled)" v-model="ienabled"></InputSwitch>
  </div>
</div>
</template>

<script>
export default {
  name : 'FeatureRowToggle',
  props: {
    available: {
      default: true,
    },
    availableInPlan: {
      default: false,
    },
    enabled: {
      default: false,
    },
    disabled: {
      default: false,
    },
    title: {
      default: ''
    },
    grayed: {
      default: false
    }
  },
  components: {},
  data() {
    return {
      ienabled: this.enabled,
    }
  },
  watch: {
    enabled: function(val) {
      this.ienabled = val;
    },
    ienabled: function (val) {
      this.$emit("update:enabled", val);
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
.not-available-feature {
  opacity: 0.4;
  color: var(--thin-darkgray);
  cursor: pointer;
}
</style>