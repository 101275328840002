<template>
  <div class="vld-parent">
    <CommonDialog ref="cDialog"></CommonDialog>
    <div class="p-mt-6 p-ml-6 p-mr-6">
      <div class="header-template">
        <div class="p-grid">
          <div class="p-col-12">
            <h1 class="p-mt-0 p-mb-0">{{ $t('account.title') }}</h1>
          </div>
        </div>
      </div>
      <div class="p-grid" >
        <!-- right side -->
        <div class="p-col-12 col-md-10 p-py-4 p-px-4">
          <div class="p-grid p-m-0">
            <div class="p-col-12 col-lg-8 p-p-0">
              <Skeleton v-if="isLoading" width="10rem" height="2rem"/>
              <h2 v-else class="p-mt-1 p-mb-0 p-ml-0">{{ $t("account.planDetails.planLabel") }}: {{ planName || $t('account.noActivePlan') }}<Tag v-if="subscriptionExpired" :value="$t('account.expired')" severity="danger" class="p-ml-2 expired" rounded></Tag></h2>
            </div>
            <div class="p-col-12 col-lg-8 p-px-0 p-pt-4">
              <Skeleton v-if="isLoading" style="width:8rem;height:2rem"/>
              <div v-if="!isLoading">
                <Button v-if="trialSubscription || (inSubscriptionMode && subscriptionExpired)" :label="$t('account.planDetails.getPlanButton')" class="primary p-button-sm p-pr-4 p-pl-4" @click="buyPlan()"></Button>
                <Button v-if="inSubscriptionMode && !subscriptionExpired" :label="$t('account.planDetails.managePlan')" class="primary p-button-sm p-pr-4 p-pl-4" @click="managePlan()"></Button>
                <Button v-if="inSubscriptionMode && subscriptionExpired" :label="$t('account.planDetails.viewBillingInvoices')" class="primary p-ml-2 p-button-sm p-pr-4 p-pl-4 p-button-outlined" @click="managePlan()"></Button>
              </div>
            </div>
          </div>
          <!-- Plan Details-->
          <div class="p-grid p-mr-0 p-ml-0 p-mt-3">
            <div class="p-col-12 col-lg-8 p-px-0">
              <div class="p-p-3 p-m-0" :class="{'capsule': !isLoading}">
                <div v-if="isLoading">
                  <GridSkeleton v-for="inx in 3" :key="inx" cols="1"></GridSkeleton>
                </div>
                <div v-else>
                  <div class="p-pb-1">{{$t("account.planDetails.purchased")}}:&nbsp;{{users}}</div>
                  <!-- <div class="p-pb-1">{{$t("account.planDetails.purchaseDate")}}:&nbsp;{{purchaseDate}}</div> -->
                  <div>{{$t("account.planDetails.renewalDate")}}:&nbsp;{{expirationDateString}}</div>
                </div>
              </div>
            </div>
            <div class="p-col-12 col-lg-8 p-px-0 p-mt-2">
              <Skeleton v-if="isLoading" style="width:10rem;height:1.5rem;"/>
              <h4 v-else><u><router-link to="plans">{{$t('account.showAvailablePlans')}}</router-link></u></h4>
            </div>
          </div>
          <div class="p-col-12 col-lg-8 p-px-0 p-mt-4">
            <AccountCard :loading="isLoading" :compact="true" @updated="accountInfoUpdated" :data="accountInfo"></AccountCard>
          </div>
          <div class="p-col-12 col-lg-8 p-px-0 p-mt-3">
            <a class="danger-link" href="#" @click="showPlanRemovingDlg">{{$t('account.remove')}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <account-deletion ref="accountDeletionDlg" @delete="doDelete"/>
</template>
<script>
import { stripeService } from "../services/stripe.service";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import { mapState, mapGetters } from 'vuex';
import AccountCard from '../components/AccountCard.vue';
import AccountDeletion from '../components/AccountDeletion.vue';
import GridSkeleton from '../components/GridSkeleton.vue';
import CommonDialog from '../components/CommonDialog.vue';
import { adminService } from '../services/admin.service';
import { authService } from '../services/auth.service';
import { ErrorHelper } from '../helpers/ErrorHelper';

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isLoading: true,
      hookDelay: 4000,
    };
  },
  computed: {
    ...mapState({
      accountInfo: (state) => state.account.companyInformation,
      inTrialMode: (state) => state.account.inTrialMode,
      inSubscriptionMode: (state) => state.account.inSubscriptionMode,
      planId: (state) => state.subscription.planId,
      users: (state) => state.subscription.users,
      expiration: (state) => state.account.expiration,
    }),
    ...mapGetters({
      subscriptionExpired: 'subscriptionExpired',
      expirationDateString: 'expirationDateString',
      planName: 'planName',
    }),
    trialSubscription: function() {
      return this.inTrialMode || (!this.inSubscriptionMode && !this.inTrialMode);
    },
  },
  created() {
  },
  components: {
    AccountCard,
    AccountDeletion,
    GridSkeleton,
    CommonDialog
  },
  validations() {
    return {
      paymentMethod: {
        cardHolderName: {
          required: requiredIf(function () {
            return this.updattingPaymentMethod;
          }),
        },
      },
    };
  },
  mounted() {
    this.showPlanDialogMsg().then((_res) => {
      if (!_res?.bypassed) {
        adminService.refreshUserData().then(_t => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    });
  },
  methods: {
    showPlanRemovingDlg() {
      this.$refs.accountDeletionDlg.show();
      return false;
    },
    showPlanDialogMsg() {
      let { planmsg, ...query } = this.$route.query;
      let icon, title, msg, cback = null, _clearParam = true;
      switch(planmsg) {
        case "buy_subscription_success":
          title = this.$t(`account.planMessages.${planmsg}.title`);
          msg = this.$t(`account.planMessages.${planmsg}.message`);
          icon = "thin-check success";
          cback = () => {
            return new Promise((res, _rej) => {
              setTimeout(() => res(), this.hookDelay);
            });
          };
          break;
        case "update_subscription_success":
          cback = () => {
            return new Promise((res, _rej) => {
              setTimeout(() => res(), this.hookDelay);
            });
          };
          break;
      }

      if (_clearParam && planmsg) {
        this.$router.replace({ query: { ...query } });
      }

      if (icon && title && msg) {
        return this.$refs.cDialog.show({
          title: title,
          message: msg,
          icon: icon,
        }).then(cback ? cback : () => {});
      }

      return cback ? cback() : Promise.resolve({ bypassed: true });
    },
    managePlan() {
      stripeService.getCustomerPortalSession({
        "return_url": `${location.origin}${this.$router.getRoutes().filter(t => t.name == "account")[0]?.path}?planmsg=update_subscription_success`,
      }).then(res => {
        let url = res?.data?.data?.response?.url;
        if (url) location.href = url;
      });
    },
    buyPlan() {
      this.$router.push("plans");
    },
    accountInfoUpdated(_res) {
      this.$store.state.account.companyInformation = _res;
    },
    doDelete(info) {
      adminService.deleteAccount(info)
        .then(
          authService.logout
        ).catch(err => {
          let ec = ErrorHelper.getErrorCode(err);
          let errMessage = ec == ErrorHelper.RESPCODE_STRIPE_ACTIVE_SUBSCRIPTION_EXIST ? this.$t('account.deletion.errors.hasActiveSubscription') : ErrorHelper.getErrorMessage(err);
          this.$refs.cDialog.show({
            title: this.$t('errorDialog.title'),
            message: errMessage,
            icon: 'thin-cancel error',
          });
        });
    }
  },
};
</script>