<template>
  <div class="sidepage">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SidePageContent"
};
</script>