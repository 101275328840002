<template>
    <div class="extended-url" @mouseenter="show" @mouseleave="hide" :class="{ 'active': active }" >
        <div ref="link" class="link">
            <slot name="content">
                <a :href="url">{{text || url}}</a>
            </slot>
        </div>
        <div class="action" v-tooltip.top="$t('services.link')" :class="{ 'thin-copy': !copied, 'thin-check': copied }" @click="copyToClipboard()" @mouseenter="show" @mouseleave="hide"></div>
    </div>
</template>
<script>
import ClipboardHelper from '../helpers/ClipboardHelper';

export default {
    name: "ExtendedUrl",
    tag: "extended-url",
    props: {
        "url": { default: null },
        "text": { default: null },
        "alwaysVisible": { type: Boolean, default: false }
    },
    data() {
        return {
            copied: false,
            active: this.alwaysVisible,
            timeout: null
        }
    },
    methods: {
        copyToClipboard() {
          ClipboardHelper.copyText(this.url);
          this.copied = true;
        },
        show() {
            this.copied = false;
            this.active = true;
            if (this.alwaysVisible !== true && this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
        },
        hide() {
            if (this.alwaysVisible !== true) {
                if (this.timeout)
                    clearTimeout(this.timeout);
                this.timeout = setTimeout(function(ctx) {
                    ctx.active = false;
                    ctx.timeout = false;
                }, 1000, this);
            }
        }
    }
}
</script>