<template>
    <div class="avatar-group" @click="toggle">
        <Avatar :label="initials" class="p-avatar-circle p-4 p-mr-0"/>
        <div :class="currentClass" class="p-ml-1 p-mr-5"></div>
    </div>
    <TieredMenu ref="topmenu" :model="filteredOptions" :popup="true" class="p-mt-2 avatarOptions shadow" @click="process" @hide="deactivate">
      <template #item="{item}">
        <hr v-if="item.type==types.SEPARATOR"/>
        <a v-else-if="item.type==types.LINK" class="p-menuitem-link" role="menuitem">
          <span class="p-menuitem-icon left" :class="item.icon" v-if="iconAlign != 'right'"></span>
          <span class="p-menuitem-text">{{item.label}}</span>
          <span class="p-menuitem-icon right" :class="item.icon" v-if="iconAlign == 'right'"></span>
        </a>
        <div v-else class="menu-element">
          <div v-for="component in item.components" :key="component">
            <option-menu-account v-if="component.componentName == 'OptionMenuAccount'" :label="this.$t('avatar.accountId')" :id="this.$store.state.auth.user.accountId"
            :email="this.$store.state.auth.user.email" :username="fullname" :buttonLabel="this.$t('avatar.copyAccountId')" @click="saveDataToClipboard"/>
            <option-menu-plan v-else-if="component.componentName == 'OptionMenuPlan'" :plan="plan"/>
            <component v-else v-bind:is="component.componentName" :class="component.class" :value="component.value"></component>
          </div>
        </div>
      </template>
    </TieredMenu>
</template>
<script>
import OptionMenuAccount from './OptionMenuAccount.vue';
import OptionMenuPlan from './OptionMenuPlan.vue';
import Avatar from 'primevue/avatar';
// import TieredMenu from 'primevue/tieredmenu';
import ClipboardHelper from '../helpers/ClipboardHelper';
import TieredMenu from '../components/tieredmenu/TieredMenu.vue';
const AvatarItemTypes = Object.freeze({
  SEPARATOR: 0,
  LINK: 1,
  CUSTOM: 2
});
export default {
  name: "AvatarMenu",
  tag: "AvatarMenu",
  props: {
    user: { type: Object },
    plan: { type: Object },
    options: { type: Array },
    activeClassname: { default: 'p-dropdown-trigger-icon pi pi-chevron-up' },
    inactiveClassname: { default: 'p-dropdown-trigger-icon pi pi-chevron-down' },
    iconAlign: { default: 'left' },
  },
  emits: ['select'],
  components: { Avatar, TieredMenu, OptionMenuAccount, OptionMenuPlan },
  data() {
    return {
      active: false,
      currentClass: this.inactiveClassname,
      types: AvatarItemTypes
    }
  },
  computed: {
    initials: function() { return this.userInitials(); },
    fullname: function() { return this.$store.state.auth.user.firstName + ' ' + this.$store.state.auth.user.lastName },
    filteredOptions: function() {
      let fo = this.options.filter((item) => item.condition !== false);
      return fo;
    }
  },
  methods: {
    userInitials() {
      let i = "?";
      if (this.user && this.user.lastName != undefined) {
        i = (this.user.firstName[0] + this.user.lastName[0]).toUpperCase();
      }
      return i;
    },
    activate: function(event) {
      if (!this.active) {
        this.active = true;
        this.currentClass = this.activeClassname;
        this.$refs.topmenu.show(event);
      }
    },
    deactivate: function(event) {
      if (this.active) {
        this.active = false;
        this.currentClass = this.inactiveClassname;
        if (this.$refs.topmenu.visible)
          this.$refs.topmenu.hide(event);
      }
    },
    toggle: function(event) {
      (this.active) ? this.deactivate(event) : this.activate(event);
    },
    process: function(event) {
      let t = event.target.innerText;
      if (t==="") t = event.target.parentElement.innerText;
      this.$refs.topmenu.hide(event);
      this.$emit("select", t);
    },
    saveDataToClipboard: function(value) {
      ClipboardHelper.copyText(value);
    }
  },
  AvatarItemTypes: AvatarItemTypes
};
</script>