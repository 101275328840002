export default {
    data() {
        return {
            internal: {
                show: false,
                resolve: null
            },
        }
    },
    methods: {
        emitResponse(res, emitEvent = true, eventName = res) {
            if (emitEvent) this.$emit(eventName, res);

            this.internal.show = false;
            if (this.internal.resolve) this.internal.resolve(res);
        },
        async show() {
            return new Promise(resolve => {
                this.internal.resolve = resolve;
                this.internal.show = true;
            });
        },
    }
}