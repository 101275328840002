<template>
  <div class="optionMenuPlan">
    <div><span class="menu-item-part label">{{ $t("planDetails.planLabel") }}:</span><b class="menu-item-part" @click="gotoPlan">{{ plan?.name }}</b></div>
    <div class="details"><span class="label">{{ $t("planDetails.renewalDate") }}:</span><span class="data" :class="{'danger':plan.expired}">{{ expiration }}</span></div>
    <div class="details"><span class="label">{{ $t("planDetails.usersLabel") }}:</span><span class="data">{{ plan.users }}</span></div>
  </div>
</template>
<script>
export default {
  name: "OptionMenuPlan",
  tag: "option-menu-plan",
  props: {
    plan: { type: Object }
  },
  emits: ["click"],
  data() {
    return {
      expiration: new Date(this.plan?.expiration || 0).toLocaleDateString()
    }
  },
  methods: {
    gotoPlan: function() {
      this.$router.push("account");
    }
  }
}
</script>