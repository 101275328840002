<template>
  <div class="vld-parent">
    <div class="p-mt-6 p-ml-6 p-mr-6">
      <div class="header-template">
        <div class="p-grid">
          <div class="p-col-12" :class="{'text-center': !joinedCards}">
            <h1 class="p-mt-0 p-mb-0">{{ $t('plans.title') }}</h1>
          </div>
        </div>
      </div>
      <div class="p-mt-0">
        <div class="p-col-12 p-pt-5 p-text-center pricing" v-if="!onlyChecks">
          <double-toggle v-model="monthly"/>
        </div>
        <div class="p-col-12">
          <div class="p-plans">
            <plan-card-features :show-features="onlyChecks" v-model="monthly" :includeSelector="true" price-place="both"></plan-card-features>
            <div :class="{ 'plan-container': joinedCards }" @mouseleave="onLeave">
              <plan-card v-for="plan in 3" :key="plan" :plan="plan" :quiet="isQuiet" :monthly="monthly" :only-checks="onlyChecks" :indy="!joinedCards" selectable="true" price-place="both" :id="'plan'+plan" @mouseenter="onEnter" @select="select" @purchase="checkout(plan)"></plan-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <resizer @resize="onResize"></resizer>
</template>

<script>
import PlanCardFeatures from "../components/plans/PlanCardFeatures.vue";
import DoubleToggle from "../components/DoubleToggle.vue";
import PlanCard from "../components/plans/PlanCard.vue";
import Resizer from "../components/Resizer.vue";
import { stripeService } from "../services/stripe.service";
import { mapState, mapGetters } from 'vuex';

const MS_TIMEOUT = 1000, MIN_TABLE_WIDTH = 1200, MIN_JOINED_TABLE = 1040;

export default {
  data() {
    return {
      monthly: false,
      isQuiet: true,
      timeout: null,
      onlyChecks: true,
      joinedCards: true
    };
  },
  computed: {
    ...mapState({
      inSubscriptionMode: (state) => state.account.inSubscriptionMode,
    }),
    ...mapGetters({
      subscriptionExpired: 'subscriptionExpired',
    }),
  },
  components: {
    PlanCard, PlanCardFeatures, DoubleToggle, Resizer
  },
  methods: {
    onEnter() {
      if (this.timeout) clearTimeout(this.timeout);
      this.isQuiet = false;
    },
    onLeave() {
      this.timeout = setTimeout(function(_ref) { _ref.isQuiet = true; _ref.timeout = null  }, MS_TIMEOUT, this);
    },
    select(p) {
      if (!this.joinedCards)
        document.getElementById("plan"+p).scrollIntoView({ behavior: "smooth" });
      else
        this.checkout(p);
    },
    managePlan() {
      stripeService.getCustomerPortalSession({
        "return_url": `${location.origin}${this.$router.getRoutes().filter(t => t.name == "account")[0]?.path}?planmsg=update_subscription_success`,
      }).then(res => {
        let url = res?.data?.data?.response?.url;
        if (url) location.href = url;
      });
    },
    checkout(planid) {
      if (this.inSubscriptionMode && !this.subscriptionExpired) {
        this.managePlan();
        return;
      }

      stripeService.getCheckoutSession({
        planid: planid,
        interval: this.monthly ? "monthly" : "yearly",
        success_url: `${location.origin}${this.$router.getRoutes().filter(t => t.name == "account")[0]?.path}?planmsg=buy_subscription_success`,
        cancel_url: location.href,
        quantity: 1,
      }).then(res => {
        let url = res?.data?.data?.response?.url;
        if (url) location.href = url;
      });
    },
    onResize({ width }) {
      let oc = width >= MIN_TABLE_WIDTH; if (oc != this.onlyChecks) { this.onlyChecks = oc; }
      let jc = width >= MIN_JOINED_TABLE; if (jc != this.joinedCards) { this.joinedCards = jc; }
    }
  }
};
</script>