<template>
  <div class="p-col-12">
    <div>
      <div class="p-col-8">
        <Skeleton height="15px"></Skeleton>
      </div>
    </div>
    <div>
      <div class="p-col-4">
        <Skeleton height="15px"></Skeleton>
      </div>
    </div>
    <div>
      <div class="p-col-5">
        <Skeleton height="15px"></Skeleton>
      </div>
    </div>
    <div>
      <div class="p-col-2">
        <Skeleton height="15px"></Skeleton>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardSkeleton',
  setup() {
  },
}
</script>
