import { createStore } from 'vuex';
import { ServiceModel } from '../models/service';
import { adminService } from '../services/admin.service';
import { userData } from '../models/user';
import { AccountModel } from '../models/account';
import { getTokenFromStorage } from '../services/auth.service';
import Tour from './_modules/tour';
import roles, { hasPermissions } from '../enums/roles';
import i18n from '../lang';
import router, { config as routerConfig } from '../router';
import PlanHelper from '../helpers/PlancardHelper';
import { throttle } from '../helpers/commonHelper';

export const store = createStore({
  modules: {
    tour: Tour,
    subscription: {
      namespaced: true,
      state: () => ({
        planId: null,
        users: 0,
        daysLeft: 0,
        expirationDateString: "-",
        plan: null
      }),
      getters: {

        //expirationDateString: (state) => { debugger; return new Date(state.account.expiration).toDateString() }
      }
    }
  },
  state: {
    networkError: false,
    account: AccountModel,
    notifications: {
      onLogin: false,
    },
    hubSpotChatConfig: {
      loaded: false,
    },
    // subscription: {
    //   // hasAccount: (state) => {
    //   //   return state.auth.user.customerId !== '';
    //   // },
    //   // hasActiveSubscription: (state) => {
    //   //   return state.auth.user.customerId !== '';
    //   // },
    //   //selectedPlan: null,
    //   currentPlanId: null,
    //   users: 0,
    //   daysLeft: 0,
    //   expirationDateString: "-",
    // },
    isPing: false,
    isDownload: false,
    auth: {
      logged: false,
      token: getTokenFromStorage(),
      user: userData,
    },
    service: ServiceModel,
    passwordComplexity: {
      minlen: 0,
      maxlen: 0,
      mustContainUppercase: false,
      mustContainLowercase: false,
      mustContainNumbers: false,
      mustContainNonAlphanumeric: false,
    },
    ajaxState: false,
    bypassAjaxState: false,
    installerDownload: {
      started: false,
      completed: false,
      progress: 0,
      error: false,
      errorMsg: null,
      allow: true,
      background: false,
    },
    menu: {
      options: [
        {
          id: 'workspaces',
          icon: 'thin-globe',
          label: i18n.global.t('layout.workspaces'),
          route: '/admin/workspaces',
          visible: true,
        },
        {
          id: 'users',
          icon: 'thin-members',
          label: i18n.global.t('layout.users'),
          route: '/admin/users',
          visible: true,
          _showIf: (state) => hasPermissions(state.auth.user.role, roles.ADMIN),
        },
        {
          id: 'account',
          icon: 'thin-user',
          label: i18n.global.t('layout.account'),
          route: '/admin/account',
          visible: true,
          _showIf: (state) => hasPermissions(state.auth.user.role, roles.OWNER)
        }
      ],
    },
  },
  mutations: {
    userData(state, { user, account, token }) {
      state.auth.logged = true;
      state.auth.user = user;
      state.auth.token = token ?? state.auth.token;
      state.account = {
        //...state.account,
        registration: Date.parse(account.registration),
        expiration: Date.parse(account.subscription.expiration),
        inTrialMode: account.subscription.isTrial,
        inSubscriptionMode: !account.subscription.isTrial,
        companyInformation: account.companyInformation,
        workspaceWizard: Boolean(account.workspaceWizard),
      }

      state.subscription.daysLeft = Date.now() > state.account.expiration ? 0 : (Math.abs(Date.now() - state.account.expiration) / (1000 * 3600 * 24)).toFixed(2);
      state.subscription.planId = account.subscription.planId;
      state.subscription.users = account.subscription.users;
      state.subscription.plan = account.subscription.plan;
      state.subscription.customerId = account.subscription.customerId;
    },
    logout(state) {
      state.auth.logged = false;
      state.auth.user = {};
    },
    pushService(state, service) {
      state.service = service;
    },
    setPasswordComplexity(state, passwordComplexity) {
      state.passwordComplexity = passwordComplexity;
    },
    ajaxState(state, value) {
      state.ajaxState = value;
    },
    bypassAjaxState(state, bypassAjaxState) {
      state.bypassAjaxState = bypassAjaxState;
      state.bypassAjaxState = bypassAjaxState;
      if (!bypassAjaxState) state.ajaxState = false;
    },
    setInstallerProgress: throttle(function(state, val) {
      val = val >= 100 ? 100 : val;
      state.installerDownload.progress = val;
    }, 350),
    setChat(state, chatConfig) {
      state.hubSpotChatConfig = chatConfig;
      state.hubSpotChatConfig.loaded = false;
    },
    loadChat(state, value) {
      state.hubSpotChatConfig.loaded = value;
    },
    setNotificationsOnLogin(state, value) {
      state.notifications.onLogin = value;
    },
    workspaceWizard(state, value) {
      state.account.workspaceWizard = value;
    }
    // setTrialExpirationDate(state, value) {
    //   if (value && value.inTrialMode) {
    //     let expiration = value.expiration;
    //     let now = Date.now();
    //     let difference = Math.abs(now - expiration);
    //     if (now > expiration) {
    //       state.trial.daysLeft = 0;
    //     } else {
    //       state.trial.daysLeft = Math.trunc(difference / (1000 * 3600 * 24));
    //     }
    //   } else { state.trial.daysLeft = 0;}
    // },
    // setSelectedPlan(state, selectedPlan) {
    //   state.subscription.selectedPlan = selectedPlan;
    // },
  },
  actions: {
    userData({ commit }, { user, account, token }) {
      commit('userData', { user, account, token });
      if (token) localStorage.setItem('thinfinity.workspaces.token', token);
    },
    login({ dispatch }, { user, account, token }) {
      dispatch('userData', { user, account, token });
      router.push(routerConfig.defaultRoute);
    },
    logout({ commit }, { route = true } = {}) {
      commit('logout');
      localStorage.removeItem('thinfinity.workspaces.token');

      if (route) router.push('/login');
    },
    downloadInstaller({ commit, state }) {
      if (!state.installerDownload.started) {
        state.isDownload = true;
        state.installerDownload.started = true;
        state.installerDownload.completed = false;
        state.installerDownload.error = false;
        state.installerDownload.errorMsg = null;
        state.installerDownload.progress = 0;
        adminService
          .downloadInstaller((val) => {
            //console.log("installer val: " + val);
            commit('setInstallerProgress', val);
          })
          .then(() => {
            state.isDownload = false;
            state.installerDownload.started = false;
            state.installerDownload.completed = true;
          })
          .catch((e) => {
            state.installerDownload.error = true;
            state.installerDownload.errorMsg = e.message || 'Download Error';
            state.installerDownload.started = false;
            state.installerDownload.completed = false;
            state.isDownload = false;
          });
      }
    },
    cancelDownload({ state }) {
      if (state.installerDownload.started) {
        adminService.cancelDownload();
        state.isDownload = false;
        state.installerDownload.started = false;
        state.installerDownload.completed = false;
      }
    },
    workspaceWizard({ commit }, { value }) {
      adminService.updateAccountInformation({
        workspaceWizard: value ? 1 : 0,
      }, {
        config: { ajaxOptions: { ajaxState: false }}
      });
      commit("workspaceWizard", value);
    }
  },
  getters: {
    role: (state) => state.auth.user.role,
    checkPasswordComplexity: (state) => (password) => {
      let ok =
        password.length >= state.passwordComplexity.minlen &&
        password.length <= state.passwordComplexity.maxlen;
      let re = /[a-z]/;
      if (state.passwordComplexity.mustContainLowercase) {
        ok = ok && re.test(password);
      }
      if (state.passwordComplexity.mustContainUppercase) {
        re = /[A-Z]/;
        ok = ok && re.test(password);
      }
      if (state.passwordComplexity.mustContainNumbers) {
        re = /[0-9]/;
        ok = ok && re.test(password);
      }
      if (state.passwordComplexity.mustContainNonAlphanumeric) {
        re = /[\W]/;
        ok = ok && re.test(password);
      }
      return ok;
    },
    subscriptionExpired: (state) => state.subscription.daysLeft <= 0,
    expirationDateString: (state) => new Date(state.account.expiration).toDateString(),
    planName: (state) => state.account.inTrialMode ? i18n.global.t('account.trialPlan') : (state.subscription.planId > 0) ? PlanHelper.getPlan(state.subscription.planId, i18n.global.t).title : null,
    //inSubscriptionMode: (state) => !state.account.inTrialMode,
    menuOptions: (state) => {
      const options = state.menu.options;

      const t = options
        .filter((t) => (t._showIf ? t._showIf(state) : true))
        .map((t) => {
          const { _showIf, ...rest } = t;
          return rest;
        });

      return t;
    },
  },
});
