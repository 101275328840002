export const ErrorHelper = {
  getErrorMessage,
  getErrorCode,
  getLinesFrom,
  isSystemError,
  RESPCODE_NOERROR: 0,
  RESPCODE_NOTFOUND: -1,
  RESPCODE_ALREADYEXISTS: -2,
  RESPCODE_PASSWORD_DOES_NOT_MATCH_RULE: -3,
  RESPCODE_NO_PROFILES_AVAILABLE: -5,
  RESPCODE_INVALIDCREDENTIALS: -6,
  RESPCODE_CREDENTIALSEXPIRED: -7,
  RESPCODE_INVALIDCONTENT: -9,
  RESPCODE_NEEDRENEWTOKEN: -10,
  RESPCODE_INVALIDTOKEN: -11,
  RESPCODE_TOKENRENEWALEXPIRED: -12,
  RESPCODE_USERNOTVERIFIED: -13,
  RESPCODE_USERISNOTINPENDINGSTATE: -14,
  RESPCODE_PENDINGVERIFICATIONEXPIRED: -15,
  RESPCODE_DEFAULTGROUPALREADYEXISTS: -17,
  RESPCODE_FORBIDDEN: -18,
  RESPCODE_INVALIDACCOUNT: -19,
  RESPCODE_CANNOTADDMEMBERTODEFAULTGROUP: -20,
  RESPCODE_CANNOTSENDMAIL: -21,
  RESPCODE_HUBSPOTCREATECONTACTERROR: -22,
  RESPCODE_USERSLIMITEXCEDED: -31,
  RESPCODE_LICENSEEXPIRED: -32,
  RESPCODE_SERVICELIMITEXCEDED: -33,
  RESPCODE_INVALID_SUBDOMAIN: -41,
  RESPCODE_INVALID_PRIVATEGATEWAY: -42,
  RESPCODE_STATUS_NONE: -50,
  RESPCODE_STATUS_PENDING: -51,
  RESPCODE_STATUS_INACTIVE: -52,
  RESPCODE_STATUS_LOCKED: -53,
  RESPCODE_STATUS_NOT_DEFINED: -54,
  RESPCODE_RESETPASWORD_TOKEN_ESPIRED: -55,
  RESPCODE_INVALID_ROLE: -56,
  RESPCODE_GEOIP_NOT_AVAILABLE: -57,
  RESPCODE_CANNOT_GET_NEXTACCOUNTID: -100,
  RESPCODE_INTERNALSERVERERROR: -500,
  RESPCODE_FIRESTORE_FATAL_ERROR: -1000,
  RESPCODE_ACCOUNT_NOTFOUND: -1001,
  RESPCODE_STRIPE_ACTIVE_SUBSCRIPTION_EXIST: -812,

  RESPCODE_STRIPE_CUSTOMER_CREATE_ERROR           : -60,
  RESPCODE_STRIPE_CUSTOMER_NOT_FOUND              : -61,
  RESPCODE_STRIPE_PAYMENT_METHOD_CREATE_ERROR     : -62,
  RESPCODE_STRIPE_PAYMENT_METHOD_ATTACH_ERROR     : -63,
  RESPCODE_STRIPE_PAYMENT_METHOD_DETACH_ERROR     : -64,
  RESPCODE_STRIPE_SUBSCRIPTION_CREATE_ERROR       : -65,
  RESPCODE_STRIPE_SUBSCRIPTION_UPDATE_ERROR       : -66,
  RESPCODE_STRIPE_SUBSCRIPTION_CANCEL_ERROR       : -67,
  RESPCODE_STRIPE_PAYMENT_METHOD_SETDEFAULT_ERROR : -68,
  RESPCODE_STRIPE_UPDATE_CUSTOMERID_ERROR         : -69,
  RESPCODE_STRIPE_ACTIVE_SUBSCRIPTION_NOT_EXIST   : -70,
  RESPCODE_STRIPE_ACCOUNT_UPDATE_ERROR            : -72,
  RESPCODE_STRIPE_CANNOT_LOAD_CONFIGURATION       : -73,
  RESPCODE_STRIPE_CONFIG_NOT_FOUND                : -74,
  RESPCODE_STRIPE_INVALID_USER                    : -75,
  RESPCODE_STRIPE_EMAIL_NOT_VERIFIED              : -76,
  RESPCODE_STRIPE_EMAIL_DONOT_MATCH               : -77,
  RESPCODE_STRIPE_PAYMENT_INTENT_ERROR            : -78,
  RESPCODE_STRIPE_SUBSCRIPTION_UPDATE_ITEM_ERROR  : -79,
};

function isSystemError(error) {
  let re = error.response ? error.response.data.error : error.error ? error.error : error;
  return re && re.code && -re.code >= 700 && -re.code < 1000;
}

function getErrorMessage(error, { appendErrorCodeForSupport = true } = {}) {
  try {
    let re = error.response ? error.response.data.error : error.error ? error.error : error;
    if (Array.isArray(re)) {
      let ae = '';
      re.forEach((element) => {
        ae = ae + element.code + ' ' + element.message;
      });
      return ae;
    } else {
      return `${appendErrorCodeForSupport && isSystemError(re) ? `Code ${re.code} ` : ''}${re.message}`;
    }
  } catch (e) {
    return error.message;
  }
}

function getErrorCode(error) {
  try {
    let re = error.response ? error.response.data.error : error.error ? error.error : error;
    if (Array.isArray(re)) {
      return re[0].code;
    } else {
      return re.code;
    }
  } catch (e) {
    return error.code ?? 500;
  }
}

function getLinesFrom(content, separator) {
  return (content || '').split(separator);
}
