<template>
  <div>
    <div class="vld-parent">
      <ConfirmDialog ref="confirmDlg"></ConfirmDialog>
      <div class="p-mt-6 p-mr-6 p-ml-6">
        <div class="header-template">
          <div class="p-grid">
            <div class="p-col-6">
              <h1 class="p-mt-0 p-mb-0">{{ $t("services.title") }}<Button
                  v-if="subscriptionExpired"
                  icon="thin-alert color-red"
                  style="font-size:18px"
                  v-tooltip.top="$t('notifications.subscription.expired.title')"
                  class="p-button-link p-mt-0 p-mb-0 p-pt-1 p-pb-1"
                  @click="showExpiredSubscriptionNotification()"
                /></h1>

            </div>
            <div class="p-col-6 p-text-right p-d-flex p-jc-end" v-if="!readOnly">
              <Button
                :class="{ 'p-button-sm': compactMode }"
                data-v-step='add-workspace'
                :disabled="!allowAddWorkspace" v-if="allowAddWorkspace" icon="thin-plus" label="Add Workspace" @click="addWorkspace"></Button>
            </div>
          </div>
        </div>
        <resizer @resize="onResize"></resizer>
        <div v-if="compactMode == true">
          <compact-list class="mobile-table" :items="services" :isloading="loadingServices" :filtrable="false" @delete="confirmDeleteService">
            <template #loading>
              <GridSkeleton v-for="inx in 2" :key="inx" cols="3"></GridSkeleton>
              <GridSkeleton
                v-for="inx in 5"
                :key="inx"
                cols="3"
                :className="`p-opacity-${6 - inx}`"
              ></GridSkeleton>
            </template>
            <template #empty>
              <div class="p-d-flex p-jc-center p-py-4">
                <div class="p-text-center p-lineheight-2">
                  <span>{{ $t('workspaces.no_workspaces_found.message') }}</span>
                  <div v-if="!readOnly && allowAddWorkspace">
                    <span>{{ $t("workspaces.no_workspaces_found.toStart") }}</span>
                    <span class="anchor" @click="addWorkspace">{{ $t("workspaces.no_workspaces_found.addNewWorkspace") }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #noresults>
              <div class="p-d-flex p-jc-center p-py-4">
                <div class="p-text-center p-lineheight-2">
                  <span>{{ $t("workspaces.no_workspaces_match") }}</span>
                </div>
              </div>
          </template>
          <template #content="slotProps">
            <div>
              <i class="pi pi-circle-on" :class="{'error':(slotProps.item.state === 1)}" ></i>
              <b>{{slotProps.item.name}}</b>
            </div>
            <div>
              <extended-url :url="slotProps.item.urlAddress" :text="slotProps.item.urlAddress" :always-visible="true">
                <template #content>
                  <span class="anchor"
                    v-if="slotProps.item.state === 0 && activeSubscription"
                    @click="generateOneTimeURL(slotProps.item)"
                    >{{ slotProps.item.urlAddress }}
                  </span>
                  <span v-else class="list-url disabled">
                    {{ slotProps.item.urlAddress }}
                  </span>
                </template>
              </extended-url>
            </div>
            <div>
                <span>{{$t('services.machine')}}:</span> {{slotProps.item.machine}}
            </div>
            <div>
                <span>{{$t('services.users')}}:</span> {{slotProps.item.license.limits.users}}
            </div>
          </template>
          <template #actions="slotProps">
              <Button v-if="currentUserIsAdmin" icon="thin-admin" :disabled="!isActive(slotProps.item.state)"
                v-tooltip.top="$t('workspaces.openAsAdmin')" class="p-button-link" @click="generateOneTimeURL(slotProps.item, true)" />
              <Button icon="thin-edit" v-tooltip.top="$t('actions.edit')" class="p-button-link" @click="editService(slotProps.item)" />
              <Button icon="thin-members" v-tooltip.top="$t('workspaces.manageMembers')" class="p-button-link" @click="manageMembers(slotProps.item)" />
          </template>
        </compact-list>
      </div>
      <div v-else class="p-grid">
        <div class="p-col-12">
          <keep-alive>
            <DataTable
              :value="services"
              :loading="loadingServices"
              loadingIcon=""
              dataKey="id"
              :paginator="false"
              :rows="20"
              columnResizeMode="fit"
              editMode="cell"
              responsiveLayout="scroll"
              sortField="name"
              :sortOrder="1"
            >
              <template #header></template>
              <template #loading>
                <div class="loading">
                  <GridSkeleton v-for="inx in 2" :key="inx" cols="6"></GridSkeleton>
                  <GridSkeleton v-for="inx in 5" :key="inx" cols="6" :className="`p-opacity-${6 - inx}`"></GridSkeleton>
                </div>
              </template>
              <template #empty>
                <div class="p-d-flex p-jc-center p-py-4">
                  <div class="p-text-center p-lineheight-2">
                    <div class="p-col-12">
                      <span>{{ $t('workspaces.no_workspaces_found.message') }}</span>
                    </div>
                    <div v-if="!readOnly && allowAddWorkspace">
                      <span>{{ $t("workspaces.no_workspaces_found.toStart") }}</span>
                      <span class="anchor" @click="addWorkspace">{{ $t("workspaces.no_workspaces_found.addNewWorkspace") }}</span>
                    </div>
                  </div>
                </div>
              </template>
                <Column
                  field="state"
                  :header="$t('services.state')"
                  :sortable="true"
                  class="status"
                >
                  <template #body="slotProps">
                    <i
                      class="pi pi-circle-on"
                      v-if="slotProps.data.state === 0 && !subscriptionExpired"
                    ></i>
                    <i
                      class="pi pi-circle-on unavailable cursor-pointer"
                      v-if="slotProps.data.state === 0 && subscriptionExpired"
                      @click="showExpiredSubscriptionNotification"
                      v-tooltip.right="$t('notifications.subscription.expired.title')"
                    ></i>
                    <i
                      class="pi pi-circle-on error"
                      v-if="slotProps.data.state === 1"
                    ></i>
                  </template>
                </Column>
                <Column
                  field="name"
                  :header="$t('services.name')"
                  :sortable="true"
                  class="editable-cells-table"
                ></Column>
                <Column
                  field="machine"
                  :header="$t('services.machine')"
                  :sortable="true"
                ></Column>
                <Column
                  field="license.limits.users"
                  :header="$t('services.users')"
                  class="users-cell"
                  :sortable="true"
                ></Column>
                <Column field="urlAddress" header="Url" :sortable="true">
                  <template #body="slotProps">
                    <extended-url :url="slotProps.data.urlAddress" :text="slotProps.data.urlAddress" :always-visible="true">
                      <template #content>
                        <span class="anchor"
                          v-if="isActive(slotProps.data.state)"
                          @click="generateOneTimeURL(slotProps.data)"
                          >{{ slotProps.data.urlAddress }}
                        </span>
                        <span v-else class="list-url disabled">
                          {{ slotProps.data.urlAddress }}
                        </span>
                      </template>
                    </extended-url>
                  </template>
                </Column>
                <Column
                  v-if="!readOnly"
                  field="features"
                  :header="$t('services.actions')"
                  :sortable="false"
                  headerStyle="width:15%;min-width:11rem"
                >
                  <template #body="slotProps">
                    <Button
                      v-if="currentUserIsAdmin"
                      icon="thin-admin"
                      v-tooltip.top="$t('workspaces.openAsAdmin')"
                      class="p-button-link"
                      :disabled="!isActive(slotProps.data.state)"
                      @click="generateOneTimeURL(slotProps.data, true)"
                    />
                    <Button
                      icon="thin-edit"
                      v-tooltip.top="$t('actions.edit')"
                      class="p-button-link"
                      @click="editService(slotProps.data)"
                    />
                    <Button
                      icon="thin-members"
                      v-tooltip.top="$t('workspaces.manageMembers')"
                      class="p-button-link"
                      @click="manageMembers(slotProps.data)"
                    />
                    <Button
                      icon="thin-trash"
                      v-tooltip.top="$t('users.delete')"
                      class="p-button-link"
                      @click="confirmDeleteService(slotProps.data)"
                    />
                  </template>
                </Column>
              </DataTable>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="displayServiceDialog"
    :header="`Configure ${service.name}`"
    :modal="true"
    :breakpoints="{ '48rem': '100vw' }"
    :style="{ width: '48rem' }"
    @show="updateMessage"
  >
    <transition-group name="p-message" tag="div">
      <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id">{{
        msg.content
      }}</Message>
    </transition-group>

    <div class="configureServiceDlg">
      <Accordion
        :multiple="false"
        :activeIndex="0"
        class="section"
        :lazy="true"
      >
        <AccordionTab :header="$t('configureService.name')">
          <div class="p-grid">
            <div class="p-col-12 p-field">
              {{ $t("configureService.generalDescription") }}
            </div>
            <div class="p-col p-field">
              <label for="servicename"
                >{{ $t("configureService.nameTitle") }}:</label
              >
            </div>
            <div class="p-col-9 p-field p-right">
              <InputText
                id="servicename"
                v-model="service.name"
                style="width: 100%"
                autofocus
                :maxLength="getMaxLength('name')"
              ></InputText>
              <field-error :value="v$.service.name" />
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col p-field">
              <label for="subdomain"
                >{{ $t("configureService.subdomain") }}:</label
              >
            </div>
            <div class="p-col p-col-9 p-field p-right">
              <InputText
                id="subdomain"
                v-model="service.subdomain"
                :placeholder="$t('configureService.subdomainLabel')"
                class="p-col-5"
                :maxLength="getMaxLength('subdomain')"
              ></InputText
              ><i class="p-inputtext p-component domainprefix"
                >-{{ accountId }}.{{ domain }}</i
              >
              <field-error :value="v$.service.subdomain" />
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col p-field">
              <label for="userlimits"
                >{{ $t("configureService.usersTitle") }}:</label
              >
            </div>
            <div class="p-col-9 p-field">
              <InputNumber
                :min="1"
                :max="maxUsers"
                v-model="service.license.limits.users"
              ></InputNumber>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab :header="$t('configureService.authenticationsTitle')">
          <!--div class="p-grid">
            <div class="p-col-12 p-field-title">
              {{ $t('configureService.workspaceTitle') }}
            </div>
            <div class="p-col-10 p-field-desc">
              {{ $t('configureService.workspaceDescription') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch v-if="service.features.authentications && service.features.authentications.workspace"
                v-model="service.features.authentications.workspace.enabled"
              ></InputSwitch>
            </div>
          </div>
          <br /-->
          <div class="p-grid">
            <div class="p-col-12 p-field">
              {{ $t("configureService.authenticationDescription") }}
            </div>
            <FeatureRowToggle v-for="(key) in authenticationMethods"
              :key="key"
              :title="$t(`configureService.authentications.${key}`)"
              :available="service.features.authentications[key]?.available ?? true"
              :availableInPlan="inTrialMode || (plan.features.authentications[key] ?? true)"
              :enabled="service.features.authentications[key]?.enabled ?? service.features.authentications[key]"
              :disabled="service.features.advancedAuthentication"
              @update:enabled="updateAuthenticationValue($event, key)"
              ></FeatureRowToggle>
            <div v-if="service.features.authentications.activeDirectory.available ?? true" class="p-col-12 p-grid p-fluid">
              <div
                class="p-col-10 p-field"
                v-if="service.features.authentications.activeDirectory.enabled"
              >
                {{ $t('configureService.restrictAccessLabel') }}
              </div>
              <div
                class="p-col-10 p-field"
                v-if="service.features.authentications.activeDirectory.enabled"
              >
                <InputText
                  id="restrictedgroups"
                  v-model="service.features.authentications.activeDirectory.restrictedGroups"
                  style="width:100%"
                  :maxLength="getMaxLength('restrictedGroups')"
                ></InputText>
              </div>
            </div>
            <div class="p-col-12 p-grid p-fluid">
              <div
                class="p-col-10 p-field"
              >
                {{ $t('configureService.advancedAuthenticationTitle') }}
              </div>
              <div
                class="p-col p-field p-text-right"
              >
                <InputSwitch
                  v-model="service.features.advancedAuthentication"
                ></InputSwitch>
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab :header="$t('configureService.otherConfiguration')">
          <div
            v-if="
              service.features &&
              service.features.scrapers &&
              service.type === 'network'
            "
            class="p-grid"
          >
            <FeatureRowToggle v-for="(key) in scrapersOrder"
              :key="key"
              :title="$t(`configureService.scrapers.${key.toUpperCase()}`)"
              :available="service.features.scrapers[key].available ?? true"
              :availableInPlan="inTrialMode || plan.features.scrapers[key]"
              v-model:enabled="service.features.scrapers[key].enabled">
            </FeatureRowToggle>
            <FeatureRowToggle :title="$t('configureService.privateProfilesDescription')"
              :availableInPlan="inTrialMode || plan.features.privateProfiles"
              v-model:enabled="service.features.privateProfiles">
            </FeatureRowToggle>
          </div>
          <div class="p-grid">
            <FeatureRowToggle :title="$t('configureService.privateGatewayTitle')"
              :availableInPlan="inTrialMode || plan.features.privateGateway"
              :available="service.features.privateGateway.available ?? true"
              v-model:enabled="service.features.privateGateway.enabled">
            </FeatureRowToggle>
            <div v-if="service.features.privateGateway.enabled" class="p-col-10">
              <div class="p-grid">
                <div class="p-col p-field">
                  <label
                    >{{
                      $t("configureService.privateGatewayNetworkIdLabel")
                    }}:</label
                  >
                </div>
                <div class="p-col-9 p-field">
                  <InputText
                    v-model="service.features.privateGateway.networkId"
                    :disabled="
                      service.features.privateGateway.enabled === false
                    "
                    style="width: 100%"
                    :maxLength="getMaxLength('privateGateway.networkId')"
                  ></InputText>
                  <field-error
                    :value="v$.service.features.privateGateway.networkId"
                  />
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col p-field">
                  <label
                    >{{ $t("configureService.privateGatewayURLLabel") }}:</label
                  >
                </div>
                <div class="p-col-9 p-field">
                  <InputText
                    v-model="service.features.privateGateway.url"
                    :disabled="
                      service.features.privateGateway.enabled === false
                    "
                    style="width: 100%"
                    :maxLength="getMaxLength('privateGateway.url')"
                  ></InputText>
                  <field-error
                    :value="v$.service.features.privateGateway.url"
                  />
                </div>
              </div>
              <div class="grid">
                <FeatureRowToggle :title="$t('configureService.privateGatewayUseSubdomain') + ':'"
                  v-model:enabled="service.features.privateGateway.useSubdomain"
                  :availableInPlan="true"
                  :grayed="true"
                  >
                </FeatureRowToggle>
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          v-if="service.features.authentications.secondFactor.enabled"
          :header="$t('configureService.reset2FATitle')"
        >
          <div class="p-grid">
            <div class="p-col p-col-9 p-field">
              <div class="p-grid">
                <div class="p-col p-field">
                  <label for="reset2FA"
                    >{{ $t("configureService.reset2FAEmail") }}:</label
                  >
                </div>
                <div class="p-col p-col-9 p-field">
                  <InputText
                    id="reset2FA"
                    v-model="reset2FAEmailOrUserId"
                    style="width: 100%"
                    :maxLength="getMaxLength('reset2FA')"
                  ></InputText>
                  <field-error :value="v$.reset2FAEmailOrUserId" />
                </div>
              </div>
            </div>
            <div class="p-col p-col-3 p-field p-text-center">
              <Button
                :label="$t('configureService.reset2FAButton')"
                @click="reset2FA()"
              ></Button>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
    <template #footer>
      <div>
        <Button
          ref="saveBtn"
          class="p-button-primary"
          label="Save"
          @click.prevent="updateService"
        />
        <Button
          ref="cancelBtn"
          label="Cancel"
          class="p-button-secondary"
          @click="closeServiceDialog"
        />
      </div>
    </template>
  </Dialog>
  <members-editor ref="membersEditor"></members-editor>
  <CommonDialog ref="dialog"></CommonDialog>
  <setup-wizard @completed="workspaceSetupWizardFinished" @dismissed="workspaceSetupWizardDismissed" ref="workspaceSetupWizard"></setup-wizard>
</template>

<script>
import { adminService } from '../services/admin.service';
import { ErrorHelper } from '../helpers/ErrorHelper';
import UserHelper from '../helpers/UserHelper.js';
import { ServiceModel } from '../models/service';
import { FilterMatchMode } from 'primevue/api';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, and, url, helpers, minLength, maxLength } from '@vuelidate/validators';
import FieldError from '../components/FieldError.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { apiConfig } from '../config/backend-api';
import GridSkeleton from '../components/GridSkeleton.vue';
import CommonDialog from '../components/CommonDialog.vue';
import CompactList from '../components/CompactList.vue';
import Resizer from '../components/Resizer.vue';
import {SecurityHelper} from '../helpers/SecurityHelper';
import { notificationsService } from "../services/notifications.service";
import FeatureRowToggle from "../components/workspaces/FeatureRowToggle.vue";
import ExtendedUrl from "../components/ExtendedUrl.vue";
import MembersEditor from "../components/workspaces/MembersEditor.vue";
import roles from '../enums/roles';
import SetupWizard, { setupWizardModes } from '../components/workspaces/SetupWizard.vue';
import { wizardService } from '../services/wizard.service';

const COMPACT_LIMIT = 800;
const MAX_USERS = 65535;

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Accordion, AccordionTab, FieldError, GridSkeleton, CommonDialog, CompactList, MembersEditor, Resizer, ExtendedUrl, FeatureRowToggle, SetupWizard },
  name: 'Workspaces',
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalMembers: [],
      getMaxLength: (property) => {
        return SecurityHelper.getPropertyLength("workspaces", property, true);
      },
      getLengthVal: (property) => {
        return SecurityHelper.getPropertyLength("workspaces", property, false);
      },
      reset2FAEmailOrUserId: "",
      validateReset2FAEmailOrUserId: true,
      successMessage: "",
      changingAuthentications: false,
      cancelPing: false,
      ping: {
        every: 30 * 1000,
        tout: null,
        enabled: false
      },
      messages: [],
      //updatingService: false,
      readyToPing: false,
      serviceIsDown: true,
      headerMessage: "",
      errorMessage: "",
      displayServiceDialog: false,
      services: [],
      loadingServices: true,
      service: ServiceModel,
      originalValue: "",
      domain: apiConfig.domain,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      isWindows: navigator.userAgent.indexOf("Windows ") !== -1,
      width: window.innerWidth,
      showMembersEditor: false,
      filteredMembers: [],
      selectedGM: null,
      allowAddWorkspace: false,
    };
  },
  computed: {
    accountId() {
      return this.$store.state.auth.user.accountId;
    },
    maxUsers() {
      return MAX_USERS;
    },
    ...mapState({
      progressValue: (state) => state.installerDownload.progress,
      downloading: (state) => state.installerDownload.started,
      plan: (state) => state.subscription.plan ?? {},
      inTrialMode: (state) => state.account.inTrialMode,
      workspaceWizard: (state) => state.account.workspaceWizard
      //allowInstaller: (state) => state.installerDownload.allow,
    }),
    ...mapGetters({
      subscriptionExpired: 'subscriptionExpired',
    }),
    compactMode() {
      return this.width <= COMPACT_LIMIT;
    },
    activeSubscription() {
      return this.$store.state.subscription.daysLeft > 0;
    },
    availableMembers() {
      return this.globalMembers.filter((member) => !member.selected && !member.isOwner)
    },
    selectedMembers() {
      return this.globalMembers.filter((member) => member.selected || member.isOwner)
    },
    currentUserIsAdmin() {
      return this.$store.state.auth.user.role == roles.ADMIN || this.$store.state.auth.user.role == roles.OWNER;
    },
    authenticationMethods() {
      const _order = ['google', 'microsoft', 'linkedin', 'facebook', 'secondFactor', 'activeDirectory'];
      const hiddenMethods = ["workspace"];

      let keys = Object.keys(this.service.features.authentications).filter(key => !hiddenMethods.includes(key)).sort((a, b) => {
        let ainx = _order.findIndex(t => t == a);
        ainx = ainx !== -1 ? Math.abs(ainx - (_order.length - 1)) : ainx;
        let binx = _order.findIndex(t => t == b);
        binx = binx !== -1 ? Math.abs(binx - (_order.length - 1)) : binx;

        if (ainx < binx) {
          return 1;
        } else if (ainx > binx) {
          return -1;
        }

        return 0;
      });

      return keys;
    },
    scrapersOrder() {
      const _order = ['rdp', 'wvpn', 'dav'];
      let keys = Object.keys(this.service.features.scrapers).sort((a, b) => {
        let ainx = _order.findIndex(t => t == a);
        ainx = ainx !== -1 ? Math.abs(ainx - (_order.length - 1)) : ainx;
        let binx = _order.findIndex(t => t == b);
        binx = binx !== -1 ? Math.abs(binx - (_order.length - 1)) : binx;

        if (ainx < binx) {
          return 1;
        } else if (ainx > binx) {
          return -1;
        }

        return 0;
      });
      return keys;
    }
  },
  validations() {
    const httpsUrl = (value) =>
      !helpers.req(value) || value.toLowerCase().indexOf("https") === 0;
    const validSubdomain = helpers.regex(
      /^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]+$/
    );
    return {
      reset2FAEmailOrUserId: {
        required: requiredIf(function () {
          return this.validateReset2FAEmailOrUserId;
        }),
      },
      service: {
        name: { required },
        subdomain: {
          required,
          minLength: minLength(this.getLengthVal("subdomain").minLength),
          maxLength: maxLength(this.getLengthVal("subdomain").maxLength),
          subdomainOk: helpers.withMessage("Invalid subdomain", validSubdomain),
        },
        features: {
          privateGateway: {
            url: {
              shouldBeChecked: and(url, httpsUrl),
              minLength: minLength(
                this.getLengthVal("privateGateway.Url").minLength
              ),
              maxLength: maxLength(
                this.getLengthVal("privateGateway.Url").maxLength
              ),
              required: requiredIf(function () {
                return this.service.features.privateGateway.enabled;
              }),
            },
            networkId: {
              minLength: minLength(
                this.getLengthVal("privateGateway.networkId").minLength
              ),
              maxLength: maxLength(
                this.getLengthVal("privateGateway.networkId").maxLength
              ),
              required: requiredIf(function () {
                return this.service.features.privateGateway.enabled;
              }),
            },
          },
        },
      },
    };
  },
  mounted() {
    //this.getServices({ config: { ajaxOptions: { ajaxState: false } } });
    this.startPing({ pingOptions: { servicesConfig: { config: { ajaxOptions: { ajaxState: false } }, gridSkeleton: true } } }).then(() => {
      if (this.$store.state.auth.user.role === roles.OWNER && !this.workspaceWizard) {
        if (this.services.length == 0) this.$refs.workspaceSetupWizard.show({ mode: setupWizardModes.FIRST_WORKSPACE_SETUP });
        else this.workspaceSetupWizardCompleted();
      }

      this.checkLocalWorkspaceAgent();
    });
    this.getUsers();
  },
  unmounted() {
    //this.readyToPing = false;
    //this.cancelPing = true;
    this.stopPing();
    clearTimeout(this.timerId);
  },
  methods: {
    ...mapActions({
      startTour: "tour/startTour",
    }),
    updateAuthenticationValue(value, key) {
      if ((this.service.features.authentications[key].enabled ?? null) != null) {
        this.service.features.authentications[key].enabled = value;
      } else {
        this.service.features.authentications[key] = value;
      }
    },
    reset2FA() {
      this.validateReset2FAEmailOrUserId = true;
      this.v$.reset2FAEmailOrUserId.$touch();
      if (this.v$.$invalid) {
        return;
      }
      adminService
        .reset2FA(this.service.id, this.reset2FAEmailOrUserId)
        .then(() => {
          this.showMessage(
            this.$t("configureService.reset2FASuccessMessage", {
              username: this.reset2FAEmailOrUserId,
            }),
            () => {
              this.reset2FAEmailOrUserId = null;
              this.v$.reset2FAEmailOrUserId.$reset();
            }
          );
        })
        .catch((error) => {
          this.showError(ErrorHelper.getErrorMessage(error));
        });
    },
    formatUrl(data) {
      if (data.features.privateGateway.enabled) {
        if (data.features.privateGateway.useSubdomain) {
          return data.features.privateGateway.url.replace("://", "://" + data.subdomain + ".");
        }
        return data.features.privateGateway.url;
      } else {
        return (
          "https://" +
          data.subdomain +
          "-" +
          this.$store.state.auth.user.accountId +
          "." +
          data.domain
        );
      }
    },
    isActive(state) {
      return state === 0 && this.activeSubscription
    },
    // initPing() {
    //   let delay = 30 * 1000;
    //   this.timerId = setTimeout(
    //     function request(_this) {
    //       _this.getServices({ config: { ajaxOptions: { ajaxState: false } } });
    //       if (!_this.cancelPing) {
    //         _this.timerId = setTimeout(request, delay, _this);
    //       }
    //     },
    //     delay,
    //     this
    //   );
    // },
    startPing({ pingOptions = {} } = {}) {
      this.ping.enabled = true;
      return this.doPing(pingOptions);
    },
    stopPing() {
      this.ping.enabled = false;
      clearTimeout(this.ping.tout);
      this.ping.tout = null;
    },
    async doPing({ resume = false, servicesConfig = {} } = {}) {
      clearInterval(this.ping.tout);
      this.ping.tout = null;

      await this.getServices({
        config: {
          ajaxOptions: {
            ajaxState: false
          }
        }, ...servicesConfig
      });

      this.ping.enabled = resume || this.ping.enabled;
      if (this.ping.enabled) {
        this.ping.tout = setTimeout(this.doPing, this.ping.every);
      }
    },
    availableInPlan(feature) {
      let available = (this.inTrialMode) ? true : false;
      if (!available) {
        try {
          available = this.getDescendantProp(this.plan, feature) ?? false;
        } catch { available = false; }
      }
      return available;
    },
    getDescendantProp(obj, desc) {
      var arr = desc.split(".");
      while(arr.length && (obj = obj[arr.shift()]));
      return obj;
    },
    checkServices({ config = {} } = {}) {
      // if (!this.readyToPing || this.cancelPing) {
      //   return;
      // }
      return adminService
        .pingServices({ config })
        .then((response) => {
          let status = response.data.data.status;
          this.services.forEach((s) => {
            status.forEach((ping) => {
              if (s.id == ping.serviceid) {
                s.state = ping.ready ? 0 : 1;
              }
            });
          });
        })
        .catch(() => {
          console.log(
            "Ping Error to " + url + " " + new Date().toLocaleTimeString()
          );
        });
    },
    refreshProgressBar(value) {
      this.progressValue = value;
      if (value >= 100) {
        this.progressValue = 100;
      }
    },
    closeServiceDialog() {
      this.displayServiceDialog = false;
      //this.readyToPing = true;
      //this.updatingService = false;
    },
    updateMessage() {
      this.messages = [];
    },
    manageMembers(workspace) {
      this.service = workspace;
      this.$refs.membersEditor.show({ groupId: workspace.id, groupName: workspace.name, users: this.globalMembers });
    },
    updateService() {
      this.validateReset2FAEmailOrUserId = false;
      this.v$.$touch();
      if (!this.v$.$invalid) {
        // this.readyToPing = false;
        if (this.service.features.privateGateway.enabled) {
          this.service.domain = this.service.features.privateGateway.url;
        } else {
          this.service.features.privateGateway.useSubdomain = false;
          this.service.features.privateGateway.networkId = "";
          this.service.features.privateGateway.url = "";
          this.service.domain = this.domain;
        }
        this.serviceIsDown = this.service.state === 1;
        adminService
          .updateService(this.serviceIsDown, this.service)
          .then(() => {
            this.closeServiceDialog();
            this.doPing({ servicesConfig: { gridSkeleton: false } });
          })
          .catch((error) => {
            this.showError(ErrorHelper.getErrorMessage(error), () => {
              //this.readyToPing = true;
            });
          });
      }
    },
    editService(net) {
      //this.updatingService = true;
      //this.readyToPing = false;
      adminService
        .getServices(net.id)
        .then((response) => {
          let r = { response };
          if (r.response.data.data.service) {
            this.service = r.response.data.data.service;
            this.service.state = net.state;
            this.reset2FAEmailOrUserId = null;
            this.v$.$reset();
            this.displayServiceDialog = true;
          }
        })
        .catch((error) => {
          this.errorMessage = ErrorHelper.getErrorMessage(error);
          this.displayMessageDialog = true;
          //this.readyToPing = true;
          //this.updatingService = false;
        });
    },
    getServices({ config = {}, gridSkeleton = false } = {}) {
      //this.readyToPing = false;
      this.loadingServices = gridSkeleton;
      if (this.loadingServices) {
        this.services = [];
      }
      return adminService
      .getServices(null, { config })
      .then(async (response) => {
        let r = { response };
        if (r.response.data.data.services) {
          let data = r.response.data.data.services.sort((a, b) =>
            a.name < b.name ? -1 : 1
          );
          data.forEach((elem) => {
            elem.urlAddress = this.formatUrl(elem);
            elem.state =
              this.services.find((t) => t.id == elem.id)?.state ?? 1;
          });
          this.services = data;
        }
        if (this.services.length > 0) await this.checkServices({ config });
        this.loadingServices = false;
        //this.readyToPing = true;
      })
      .catch(async (error) => {
        if (console.warn) console.warn(ErrorHelper.getErrorMessage(error));
        if (this.services.length > 0) await this.checkServices({ config });
        this.loadingServices = false;
        //this.readyToPing = true;
      });
    },
    confirmDeleteService(net) {
      //this.readyToPing = false;
      this.$refs.confirmDlg
        .show({
          title: this.$t("workspaces.confirmDeleteService.title"),
          message: this.$t("workspaces.confirmDeleteService.message", {
            name: net.name,
          }),
          icon: "thin-question",
          acceptClass: "p-button-danger",
        })
        .then((action) => {
          if (action === "ok") {
            adminService
              .deleteService(net.id)
              .then(() => {
                this.doPing({ resume: true, servicesConfig: { gridSkeleton: true } }).then(() => {
                  setTimeout(() => this.checkLocalWorkspaceAgent(), 1 * 1000);
                });
              })
              .catch((error) => {
                this.showError(ErrorHelper.getErrorMessage(error), function () {
                  //this.readyToPing = true;
                });
              });
          } else {
            //this.readyToPing = true;
          }
        });
    },
    generateOneTimeURL(sp, asAdmin) {
      let rootURL = sp.urlAddress;
      if (rootURL.endsWith("/")) rootURL = rootURL.substring(0, rootURL.length - 1);
      adminService
        .getOneTimeUrl(asAdmin)
        .then(({ data: _data = {} }) => {
          _data = _data?.data ?? {};
          /*  "noopener" the newly-opened window will open as normal, except that it will not have access back to the originating window,
           *  call will also return null, so the originating window will not have access to the new one either.
           *  This is useful for preventing untrusted sites opened via window.open() from tampering with the originating window, and vice versa.
           * */
          if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACK.toUpperCase() == "TRUE")
            if (asAdmin) {
              this.$gtag.event('action', { 'event_category' : 'navigation', 'event_label' : 'open workspace as admin' });
            } else {
              this.$gtag.event('action', { 'event_category' : 'mavigation', 'event_label' : 'open workspace as user' });
            }
          window.open(rootURL + (_data?.uri ?? ""), "_blank", "noopener");
        })
        .catch(() => {
          window.open(rootURL, "_blank");
        });
    },
    askForDownload() {
      this.$refs.confirmDlg
        .show({
          title: this.$t("workspaces.askForDownload.title"),
          message: this.$t("workspaces.askForDownload.message"),
          icon: "thin-alert",
          acceptClass: "p-button-primary",
          cancelClass: "p-button-danger",
          focus: "ok",
        })
        .then((action) => {
          if (action === "ok") this.downloadInstaller();
        });
    },
    askForCancel() {
      this.$refs.confirmDlg
        .show({
          title: this.$t("workspaces.askForCancel.title"),
          message: this.$t("workspaces.askForCancel.message"),
          icon: "thin-alert",
          acceptClass: "p-button-danger",
          cancelClass: "p-button-primary",
          focus: "cancel",
        })
        .then((action) => {
          if (action === "ok") {
            this.cancelDownload();
          }
        });
    },
    downloadInstaller() {
      if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACK.toUpperCase() == "TRUE")
        this.$gtag.event('action', { 'event_category' : 'download', 'event_label' : 'download installer start' });
      this.$store.dispatch("downloadInstaller");
    },
    cancelDownload() {
      if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACK.toUpperCase() == "TRUE")
        this.$gtag.event('action', { 'event_category' : 'download', 'event_label' : 'download installer cancel' });
      this.$store.dispatch("cancelDownload");
    },
    getThinClass(value) {
      let className = "thin-";
      switch (value) {
        case "app":
          className += "tiles";
          break;
        case "webapp":
          className += "web";
          break;
        default:
          className += value;
      }
      return className;
    },
    onResize({ width }) {
      this.width = width;
    },
    showMessage(msg, callback) {
      this.$refs.dialog
        .show({
          title: this.$t("successDialog.title"),
          message: msg,
          icon: "thin-check",
        })
        .then(() => {
          if (callback) callback();
        });
    },
    showError(errMsg, callback) {
      this.$refs.dialog
        .show({
          title: this.$t("errorDialog.title"),
          message: errMsg,
          icon: "thin-cancel error",
        })
        .then(() => {
          if (callback) callback();
        });
    },
    showExpiredSubscriptionNotification() {
      this.$toast.removeGroup("callToActionMsgs");
      notificationsService.showExpiredPlanNotification({ ctx: this });
    },
    getUsers() {
      adminService
        .getUsers()
        .then((response) => {
          let uh = new UserHelper(this.$t);
          let r = { response };
          this.globalMembers = r.response.data.data.members;
          this.globalMembers = this.globalMembers.sort((a, b) => (a.name < b.name) ? -1 : 1);
          this.globalMembers = this.globalMembers.map(m => { return { name: m.name, email: m.id, role: uh.roleName(m.level), status: uh.statusName(m.status), isOwner: uh.isOwner(m.level) } });
        })
        .catch((error) => {
          this.showError(ErrorHelper.getErrorMessage(error));
        });
    },
    searchMatchingMembers(event) {
      let m = [...this.availableMembers];
      this.filteredMembers = (!event.query.trim().length) ? m : m.filter((member) => (member.name + ' ' + member.email).toLowerCase().indexOf(event.query.toLowerCase()) != -1);
    },
    onMemberSelected(event) {
      if (event.value) {
        event.value.selected = true;
        this.selectedGM = null;
      }
    },
    onMemberUnselected(member) {
      member.selected = false;
    },
    addWorkspace() {
      this.$refs.workspaceSetupWizard.show({ mode: setupWizardModes.WORKSPACE_SETUP });
    },
    workspaceSetupWizardDismissed() {
      this.workspaceSetupWizardCompleted();
    },
    workspaceSetupWizardFinished() {
      this.allowAddWorkspace = false;
      this.doPing({ resume: true, servicesConfig: { gridSkeleton: true } }).then(() => {
        this.checkLocalWorkspaceAgent();
      });
      this.workspaceSetupWizardCompleted();
    },
    workspaceSetupWizardCompleted() {
      if (!this.workspaceWizard) {
        this.$store.dispatch("workspaceWizard", { value: true });
        this.startTour();
      }
    },
    checkLocalWorkspaceAgent() {
      wizardService.ping().then(({ data }) => {
        let hasWorkspace = data?.service?.id && !!this.services.find(t => t.id == data?.service?.id);
        this.allowAddWorkspace = !hasWorkspace;
      }).catch((_data) => {
        this.allowAddWorkspace = true;
      });
    }
  },
  watch: {
    "service.features.advancedAuthentication": function(val) {
      if (val) {
        this.authenticationMethods.forEach(key => {
          this.updateAuthenticationValue(false, key);
        });
      }
    }
  }
};
</script>
<style scoped>
h3 {
  font-weight: normal;
  color: var(--primary-color);
}
.cursor-pointer {
  cursor: pointer;
}
</style>