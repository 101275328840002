<template>
  <Dialog class="deletion p-fluid" v-model:visible="internal.show" :modal="true" :header="$t('account.deletion.title')">
    <div>
      <h4 class="p-my-4">{{$t("account.deletion.message.title")}}</h4>
      <div v-for="(index, item) in disclaimer" :key="index" class="p-my-2">{{disclaimer[item]}}</div>
      <div class="p-my-4">{{$t("account.deletion.message.instruction")}}</div>
      <form>
      <div class="recaptchaContainer p-mt-4" style="min-height: 7rem">
        <vue-recaptcha v-if="showRecaptcha" :siteKey="reCaptchaSiteKey" theme="light"
          :size="compactCaptcha ? 'compact' : 'normal'"
          @verify="reCaptchaVerified" @expire="reCaptchaExpired" @fail="reCaptchaFailed"
          ref="vueRecaptcha"></vue-recaptcha>
      </div>
      </form>
    </div>
    <template #footer>
      <div class="p-grid">
        <div class="p-col">
          <Button
            :label="$t('account.deletion.closeAndRemove')"
            class="p-button-danger" :disabled="!confirmed"
            @click.prevent="confirmDeletion()"
          />
          <Button
            :label="$t('account.cancelButton')"
            @click.prevent="closeDialog()"
            class="p-button-secondary"
            tabindex="-1"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import DialogMixin from '../mixins/DialogMixin';
import vueRecaptcha from 'vue3-recaptcha2';
import { apiConfig } from '../config/backend-api';

export default {
  name : 'AccountDeletion',
  tag: "account-deletion",
  mixins: [DialogMixin],
  components: { vueRecaptcha },
  emits: ["close", "delete"],
  data() {
    return {
      showRecaptcha: true,
      reCaptchaToken: '',
      disc: this.$t("account.deletion.message.disclaimer", {days: '7'})
    }
  },
  computed: {
    disclaimer() {
      return this.disc.split("#");
    },
    confirmed() {
      return this.reCaptchaToken !== '';
    },
    compactCaptcha() {
      return window.outerWidth < 500;
    },
  },
  mounted() {
    this.reCaptchaSiteKey = apiConfig.reCaptchaSiteKey;
    this.showRecaptcha = !!this.reCaptchaSiteKey;
  },
  methods: {
    async show(data) {
      return new Promise((resolve) => {
        Object.assign(this, data);
        this.internal.resolve = resolve;
        this.internal.show = true;
      });
    },
    confirmDeletion() {
      this.emitResponse({ token: this.reCaptchaToken }, true, "delete");
      this.reCaptchaToken = '';
    },
    reCaptchaVerified(token) {
      this.reCaptchaToken = token;
    },
    reCaptchaInvalidate() {
      this.$refs.vueRecaptcha.reset();
      this.reCaptchaToken = '';
    },
    reCaptchaExpired() {
      this.reCaptchaInvalidate();
    },
    reCaptchaFailed() {
      this.reCaptchaInvalidate();
    },
    closeDialog() {
      this.emitResponse(null, false);
      this.reCaptchaToken = '';
    }
  }
}
</script>