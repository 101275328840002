import router from "../../router";
import i18n from "../../lang";

export default {
    namespaced: true,
    state: () => {
        let tourState = {
            steps: [
                {
                    target: '[data-v-step="workspaces"]',
                    content: i18n.global.t("tour.workspaces"),
                    params: {
                        placement: 'right'
                    },
                    before: () => {
                        return new Promise((resolve) => {
                            router.push({ name: "workspaces" });
                            resolve();
                        });
                    },
                },
                // {
                //     target: '[data-v-step="add-workspace"]',
                //     content: i18n.global.t("tour.getTheInstaller"),
                //     params: {
                //         placement: 'bottom'
                //     },
                //     before: () => {
                //         return new Promise((resolve) => {
                //             router.push({ name: "workspaces" }).then(() => {
                //                 resolve();
                //             });
                //         });
                //     },
                //     filter: navigator.userAgent.indexOf('Windows ') != -1
                // },
                {
                    target: '[data-v-step="users"]',
                    content: i18n.global.t("tour.members"),
                    params: {
                        placement: 'right'
                    },
                    before: () => {
                        return new Promise((resolve) => {
                            router.push({ name: "users" });
                            resolve();
                        });
                    },
                },
                {
                    target: '[data-v-step="account"]',
                    content: i18n.global.t("tour.account"),
                    params: {
                        placement: 'right'
                    },
                    before: () => {
                        return new Promise((resolve) => {
                            router.push({ name: "account" });
                            resolve();
                        });
                    },
                },
                {
                    target: '.avatar-group',
                    content: i18n.global.t("tour.avatar"),
                    params: {
                        placement: 'left'
                    }
                },
                {
                    target: '#app',
                    content: i18n.global.t("tour.ready.text",
                        { "link": "<a href='https://thinfinity-remote-workspace-online-docs.cybelesoft.com/' target='_blank'>" + i18n.global.t("tour.ready.linkText")  + "</a>" }),
                }
            ],
            started: false,
        };

        tourState.steps = tourState.steps.filter(t => {
            return t.filter ?? true
        });

        return tourState;
    },
    actions: {
        startTour({ state }) {
            state.started = true;
        },
        stopTour({ state }) {
            state.started = false;
        }
    }
}