//import { store } from '../store';

export const notificationsService = {
    showNotificationToast,
    showExpiredPlanNotification,
}

function showExpiredPlanNotification({ ctx }) {
    showNotificationToast({
        severity: "error",
        summary: ctx.$t('notifications.subscription.expired.title'),
        detail: ctx.$t('notifications.subscription.expired.message'),
        group: "callToActionMsgs",
        callToAction: ctx.$t('notifications.subscription.expired.callToAction'),
        callToActionCallback: () => {
            ctx.$router.push("plans");
            ctx.$toast.removeGroup("callToActionMsgs");
        },
        life: 0
      }, { ctx });
}

function showNotificationToast(opts = {}, { ctx }) {
    window.setTimeout(() => {
        ctx.$toast.add({
            severity: "warn",
            group: "tmsgs",
            life: 10000,
            ...opts
        });
    }, 100);
}