<template>
  <Toolbar class="topbar">
    <template #start>
      <img class="logo" src="/images/thinfinity.svg">
    </template>
    <template #end>
      <Button
        v-if="(trialSubscription
        || (inSubscriptionMode && subscriptionExpired)) && isOwner"
        class="p-button p-button-primary p-button-sm p-py-2 p-px-3 p-mr-3"
        :class="{ 'p-button-danger': subscriptionExpired }"
        :label="subscriptionExpired ? $t('account.planDetails.upgradeAccount') : $t('account.planDetails.getPlanButton')" @click="purchasePlan()" />
      <div style="witdh:10%">&nbsp;</div>
      <AvatarMenu ref="avatarMenu" :user="user" :plan="plan" :options="options" @select="process"/>
    </template>
  </Toolbar>
  <ChangePasswordDialog ref="changePasswordDialog"></ChangePasswordDialog>
  <beta-version corner="top left"/>
</template>
<script>
import { apiConfig } from '../config/backend-api';
import { authService } from '../services/auth.service';
import AvatarMenu from './AvatarMenu.vue';
import ChangePasswordDialog from './ChangePasswordDialog.vue';
import BetaVersion from './BetaVersion.vue';
import { mapState, mapGetters } from 'vuex';
import UserHelper from '../helpers/UserHelper';

let uh = null;

export default {
  name:  'TopBar',
  components: { AvatarMenu, ChangePasswordDialog, BetaVersion },
  data() {
    return {
      options: [],
      actions: [
        null, null,
        null, null,
        this.changePassword,
        this.openHelp,
        null,
        this.logout
      ]
    }
  },
  computed: {
    user: function() { return this.$store.state.auth.user; },
    ...mapState({
      inTrialMode: (state) => state.account.inTrialMode,
      inSubscriptionMode: (state) => state.account.inSubscriptionMode,
      expiration: (state) => state.account.expiration,
      users: (state) => state.subscription.users || 'N/A',
    }),
    plan: function() {
      return {
        'name': this.planName,
        'expired': this.subscriptionExpired,
        'expiration': this.expiration,
        'users': this.users
      }
    },
    ...mapGetters({
      planName: 'planName',
      subscriptionExpired: 'subscriptionExpired',
    }),
    trialSubscription: function() {
      return this.inTrialMode || (!this.inSubscriptionMode && !this.inTrialMode);
    },
    isOwner() {
      return uh.isOwner(this.user.role);
    }
  },
  created() {
    uh = new UserHelper(this.$t);
  },
  mounted() {
    this.options = [
        {
          type: AvatarMenu.AvatarItemTypes.CUSTOM,
          components: [
            { componentName: "OptionMenuAccount" }
          ]
        },
        {
          type: AvatarMenu.AvatarItemTypes.SEPARATOR,
          condition: this.isOwner
        },
        {
          type: AvatarMenu.AvatarItemTypes.CUSTOM,
          condition: this.isOwner,
          components: [
            { componentName: "OptionMenuPlan" }
          ]
        },
        {
          type: AvatarMenu.AvatarItemTypes.SEPARATOR
        },
        {
          type: AvatarMenu.AvatarItemTypes.LINK,
          label:'Change password',
          icon:'thin-key'
        },
        {
          type: AvatarMenu.AvatarItemTypes.LINK,
          label: 'Help',
          icon: 'thin-question'
        },
        {
          type: AvatarMenu.AvatarItemTypes.SEPARATOR
        },
        {
          type: AvatarMenu.AvatarItemTypes.LINK,
          label:'Logout',
          icon:'thin-logout'
        },
      ]
  },
  methods: {
    logout(){
      authService.logout();
    },
    changePassword() {
      this.$refs.changePasswordDialog.show();
    },
    openHelp() {
      let helpURL = apiConfig.getConfigUrlValue("help");// || "https://workspaces-docs.cybelesoft.com/";
      window.open(helpURL, "help");
    },
    userInitials() {
      let i = "?";
      if (this.user && this.user.lastName != undefined) {
        i = (this.user.firstName[0] + this.user.lastName[0]).toUpperCase();
      }
      return i;
    },
    process: function(value){
      try {
        let action = this.actions[this.options.findIndex((opt) => opt.label == value)];
        if (action && typeof action == "function") {
          action();
        }
      } catch(e) {
        console.log(e.message);
      }
    },
    purchasePlan() {
      this.$router.push("plans");
    }
  },
};
</script>