<template>
  <Dialog class="tourDialog" :modal="true" v-model:visible="internal.show" header="&nbsp;" :style="{width: '40vw'}">
    <Card :style="{'box-shadow': '0 0 0 0'}">
        <template #title>
            <div class="logo"><img src="/images/thinfinity.svg" style="width:50%" /></div>
            <div class="text">{{$t("tour.welcome.title")}}</div>
        </template>
        <template #content>
            <div class="text">{{$t("tour.welcome.content")}}</div>
        </template>
        <template #footer>
        </template>
    </Card>
    <template #footer>
      <Button
        ref="okBtn"
        @click="startTour"
        :label="$t('tour.welcome.startBtn')"
        :class="acceptClass" />
      <Button
        ref="skipBtn"
        @click="skipTour"
        :label="$t('tour.welcome.skipBtn')"
        :class="acceptClass" />
    </template>
  </Dialog>
</template>

<script>
import DialogMixin from '../mixins/DialogMixin';
export default {
  name : 'TourDialog',
  emits: ["start", "skip"],
  mixins: [DialogMixin],
  data(){
      return {
      }
  },
  methods: {
      startTour() {
          this.emitResponse('start');
      },
      skipTour() {
          this.emitResponse('skip');
      },
      async show(data){
        return DialogMixin.methods.show.call(this, data);
      },
  }
}
</script>