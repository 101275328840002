<template>
    <div class="double-toggle round">
        <input type="checkbox" ref="toggleChk" class="checkbox" v-model="period" />
        <div class="knobs" :data-before="option1" :data-after="option2"></div>
        <div class="text left">{{option1}}</div>
        <div class="text right">{{option2}}</div>
    </div>
</template>
<script>
export default {
    name: "DoubleToggle",
    tag: "double-toggle",
    props: {"modelValue":null, "data":{"type":Array,"default":null} },
    emits: ["update:modelValue"],
    data() {
        let _items = (this.data)? this.data :[{"name": this.$t("plans.period.yearly"), "value": false }, {"name": this.$t("plans.period.monthly"), "value": true}];
        return {
            items: _items,
            period: this.modelValue
        };
    },
    computed:{
        option1(){
            return this.items[0].name || ""
        },
        option2() {
            return this.items[1].name || ""
        }
    },
    mounted() {
        this.updateState({ srcElement: this.$refs.toggleChk });
        return this.checked;
    },
    methods:{
        "initialState"() {
            this.checked = !(!this.items || !!this.items[0].checked);
        },
        "updateState": function (e) {
            if (this.period) {
                let checked = e.srcElement.checked;
                let idx = checked ? 1 : 0;
                this.$emit('change', checked, this.period[idx].title||this.period[idx]);
            }
        }
    },
    watch:{
         period: function(val) {
             this.$emit("update:modelValue",  val); }
    }
}
</script>