<template>
    <div class="downloader" :class="extraClass">
        <div class="p-text-left pbcont" v-if="downloading && progressValue >= 0" :class="cancelableClass">
            <ProgressBar :class="[{ 'cancelable': cancelable }, progressBarClass]" :value="progressValue" />
            <div class="cancelButton" :class="btnCancelClass" v-if="cancelable" @click="requestCancel"></div>
        </div>
        <div v-else class="p-p-0">
            <Button :icon="buttonIcon" :label="value" class="p-pl-3 p-pr-4" @click="requestDownload"></Button>
        </div>
    </div>
</template>
<script>
export default {
    name: "Downloader",
    tag: "downloader",
    props: ["class","value","iconclass","cancelable","progress","progressClass","cancelclass","downloading"],
    data() {
        return {
        }
    },
    computed:{
        progressValue() { return (typeof this.progress != "undefined") ? this.progress : -1 },
        extraClass() { return  (typeof this.class != "undefined") ? this.class : "" },
        cancelableClass() { return  (typeof this.cancelable != "undefined" && this.cancelable) ? "cancelable": "" },
        progressBarClass() { return  (typeof this.class != "undefined") ? this.progressClass : "" },
        btnCancelClass() { return  (typeof this.cancelclass != "undefined") ? this.cancelclass : "thin-cancel" },
        buttonIcon() { return (typeof this.iconclass != "undefined") ? this.iconclass : "thin-download" }
    },
    methods: {
        requestDownload() { this.$emit("requestDownload") },
        requestCancel() { this.$emit("requestCancel") },
    }
}
</script>
