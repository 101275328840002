const roles = Object.freeze({
    NONE: 0,
    USER: 1,
    ADMIN: 2,
    OWNER: 3,
    SUPERADMIN: 4,
});

export const hasPermissions = (role, permission) => {
    return role >= permission;
};

export default roles;