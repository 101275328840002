export const AccountModel = {
    state: 0, // 0=Active, 1=Inactive
    registration: null, // Fecha y hora de registracion
    // Fecha y hora de expiracion. Si inTrialMode = true es la fecha de expiracion del Trial,
    // si insubscriptionMode = true es la fecha de expiracion del plan
    expiration: null,
    inTrialMode : false,
    inSubscriptionMode : false,
    companyInformation: {
        companyName: '',
        phone: '',
        country: '',
        state: ''
    },
    trial: {
        expiration: null, // Fecha y hora de expiracion;
        users: 0, //  cantidad maxima de usuarios permitidos
        services: 0 // cantidad maxima de servicios permitidos
    },
    workspaceWizard: false,
}