<template>
  <Dialog
    :modal="true"
    v-model:visible="internal.show"
    :header="$t('workspaces.manageMembersOf', { 'name': groupName })"
    :breakpoints="{ '48rem': '100vw' }"
    :style="{ width: '48rem' }"
    class="members-editor"
    >
    <h4 class="p-mt-4 p-mb-2">{{$t("workspaces.availablePeople")}}</h4>
    <AutoComplete v-model="selectedGM" :suggestions="filteredMembers" @complete="searchMatchingMembers($event)" @item-select="onMemberSelected" :delay="100" :dropdown="true" field="name" forceSelection style="width: 100%">
      <template #item="slotProps">
        <div class="p-grid">
          <div><b>{{slotProps.item.name}}</b> [{{slotProps.item.email}}] ({{slotProps.item.role}} - {{slotProps.item.status}})</div>
        </div>
      </template>
    </AutoComplete>
    <div class="p-grid p-mt-4">
      <div class="p-col-6"><h4>{{$t("workspaces.assignedMembers")}}</h4></div>
      <div class="p-col-6 p-text-right list-actions"><Button class="p-button-link p-py-0" @click="selectAll">{{$t("workspaces.addAll")}}</Button> <Button class="p-button-link p-py-0" @click="clearAll">{{$t("workspaces.clearAll")}}</Button></div>
    </div>
    <compact-list class="mobile-table assigned-members"
      :items="selectedMembers"
      :filtrable="false" :deletable="isDeletable"
      :isloading="loading"
      @delete="onMemberUnselected"
    >
      <template #loading>
        <GridSkeleton v-for="inx in 2" :key="inx" cols="1" style="width:100%"></GridSkeleton>
        <GridSkeleton v-for="inx in 2" :key="inx" cols="1" :className="`p-opacity-${3 - inx}`" style="width:100%"></GridSkeleton>
      </template>
      <template #empty>
      <div class="p-d-flex p-jc-center p-py-4">
      <div class="p-text-center p-lineheight-2">
        <span>{{ $t('users.no_users_found') }}</span>
      </div>
      </div>
      </template>
      <template #noresults>
      <div class="p-d-flex p-jc-center p-py-4">
      <div class="p-text-center p-lineheight-2">
        <span>{{ $t('users.no_users_match') }}</span>
      </div>
      </div>
      </template>
      <template #content="slotProps">
        <div><b>{{slotProps.item.name}}</b> [{{slotProps.item.email}}] ({{slotProps.item.role}} - {{slotProps.item.status}})</div>
      </template>
      <template #actions>&nbsp;</template>
    </compact-list>
    <template #footer>
      <div>
        <Button
          ref="saveBtn"
          class="p-button-primary"
          label="Save"
          @click.prevent="updateMembers"
        />
        <Button
          ref="cancelBtn"
          label="Cancel"
          class="p-button-secondary"
          @click="cancelEdition"
        />
      </div>
    </template>
  </Dialog>
</template>
<script>
import { adminService } from '../../services/admin.service';
import DialogMixin from "../../mixins/DialogMixin";
import CompactList from '../CompactList.vue';
import GridSkeleton from '../GridSkeleton.vue';

export default {
  name: "MembersEditor",
  tag: "members-editor",
  mixins: [DialogMixin],
  components: { CompactList, GridSkeleton },
  props: {
  },
  emits: [],
  data() {
    return {
        users: [],
        groupId: null,
        groupName: null,
        selectedGM: null,
        filteredMembers: [],
        loading: true,
    };
  },
  computed: {
    availableMembers() {
      return this.users.filter((member) => !member.selected && !member.isOwner)
    },
    selectedMembers() {
      return this.users.filter((member) => member.selected || member.isOwner)
    }
  },
  mounted() {
  },
  watch: {
      "internal.show": function(v) {
          if (!v) {
              this.users = [];
              this.groupId = null;
              this.groupName = null;
          }
      }
  },
  methods: {
    async show({ groupId, groupName, users }) {
        this.groupId = groupId;
        this.groupName = groupName;
        this.users = users.map((t) => { return { ...t } });
        this.getCurrentMembers();
        DialogMixin.methods.show.call(this, arguments);
    },
    getCurrentMembers() {
        this.loading = true;
        adminService.getGroupMembers(this.groupId).then((res) => {
            let _data = res?.data?.data?.members;
            this.users = this.users.map((t) => {
                if (_data.includes(t.email)) t.selected = true;
                return t;
            });
        }).catch(() => {
            console.log("[MembersEditor] Error while loading members.");
        }).then(() => {
            this.loading = false;
        });
    },
    searchMatchingMembers(event) {
      let m = [...this.availableMembers];
      this.filteredMembers = (!event.query.trim().length) ? m : m.filter((member) => (member.name + ' ' + member.email).toLowerCase().indexOf(event.query.toLowerCase()) != -1);
    },
    isDeletable(member) {
      return !member.isOwner;
    },
    onMemberSelected(event) {
      if (event.value) {
        event.value.selected = true;
        this.selectedGM = null;
      }
    },
    onMemberUnselected(member) {
      member.selected = false;
    },
    selectAll() {
      this.availableMembers.map((m) => m.selected = true);
    },
    clearAll() {
      this.selectedMembers.map((m) => m.selected = m.isOwner);
    },
    cancelEdition() {
      this.emitResponse('cancel');
    },
    updateMembers() {
        let members = this.selectedMembers.map(t => t.email);
        adminService.updateGroupMembers(this.groupId, members).then(() => {
            this.emitResponse("saved");
        });
    },
  },
};
</script>
