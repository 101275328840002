<template>
    <span v-if="isBeta" class="beta-version" :class="corner">{{ $t("beta.label") }}</span>
</template>
<script>
export default {
    name: "BetaVersion",
    tag: "beta-version",
    props: {
        "corner": { default: "top left" }
        },
    data() {
        return {
            "isBeta": process.env.VUE_APP_BETA_VERSION == 'TRUE'
        }
    }
}
</script>
