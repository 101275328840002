<template>
  <div class="optionMenuAccount">
    <div><span class="menu-item-part label">{{ label }}</span><b class="menu-item-part">{{ id }}</b><div class="menu-item-part"><a class="minibutton thin-copy" :title="buttonLabel" @click="$emit('click', id)"></a></div></div>
    <div class="username">{{ username }}</div>
    <div class="email">{{ email }}</div>
  </div>
</template>
<script>
export default {
  name: "OptionMenuAccount",
  tag: "option-menu-account",
  props: {"label": {default: "Account"} , "id": { default: "N/A"}, "buttonLabel": { default: "copy"}, "email": { default: ""}, "username": {default: ""}  },
  emits: ["click"],
  data() {
    return {
      components: [
        { componentName: "span", label: this.label, "class": "" },
        { componentName: "b", label: this.Id, "class": "" },
        { componentName: "button", label: this.Id, "class": "menu-item-part secondary" },
      ]
    }
  }
}
</script>