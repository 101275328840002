<template>
  <Dialog @hide="onHide" class="p-fluid narrow" v-model:visible="internal.show" :modal="true">
    <template #header>
      <span class="p-dialog-title">{{ $t('changepassword.title') }}</span>
    </template>
    <form name="changepasswordForm" @keyup.enter="handleSubmit">
    <input type="text" id="username" name="username" autocomplete="username" v-model="username" class="hidden"/>
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
        <label>{{ $t('changepassword.currentPassword') }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <Password
          id="currentPassword"
          v-model="fields.currentPassword"
          toggleMask
          :feedback="false"
          autofocus
          autocomplete="current-password"
        >
        </Password>
        <field-error :value="v$.fields.currentPassword" />
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
        <label>{{ $t('changepassword.newPassword') }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <PasswordComplexity
          id="newPassword"
          v-model="fields.newPassword"
          toggleMask
          :feedback="true"
          autocomplete="new-password"
        >
        </PasswordComplexity>
        <field-error :value="v$.fields.newPassword" />
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
        <label>{{ $t('common.verify_password') }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <Password
          id="passwordConfirmation"
          v-model="fields.passwordConfirmation"
          toggleMask
          :feedback="false"
          autocomplete="new-password-confirm"
        />
        <field-error :value="v$.fields.passwordConfirmation" />
      </div>
    </div>
    </form>
    <template #footer>
      <Button :label="$t('changepassword.changeButton')" @click.prevent="handleSubmit($event)" />
      <Button :label="$t('changepassword.cancelButton')" class="p-button-secondary" @click.prevent="close()" />
    </template>
  </Dialog>
  <CommonDialog ref="dialog"></CommonDialog>
</template>
<script>
import { adminService } from '../services/admin.service';
import { ErrorHelper } from '../helpers/ErrorHelper';
import { useVuelidate } from '@vuelidate/core';
import { sameAs, required, and, requiredIf, helpers } from '@vuelidate/validators';
import PasswordComplexity from '../components/PasswordComplexity.vue';
import CommonDialog from '../components/CommonDialog.vue';
import FieldError from '../components/FieldError.vue';

export default {
  name: 'ChangePasswordDialog',
  setup: () => ({ v$: useVuelidate() }),
  components: {
    PasswordComplexity,
    FieldError,
    CommonDialog,
  },
  data() {
    return {
      internal: {
        show: false,
        resolve: null,
      },
      fields: {
        username: null,
        currentPassword: null,
        newPassword: null,
        passwordConfirmation: null,
      },
    };
  },
  validations() {
    const validPassword = (value) => this.$store.getters.checkPasswordComplexity(value);

    return {
      fields: {
        currentPassword: { required },
        newPassword: {
          required: helpers.withMessage(
            this.$t('passwordComplexity.validationError'),
            and(
              validPassword,
              requiredIf(function() {
                return this.fields.newPassword !== '';
              })
            )
          ),
        },
        passwordConfirmation: {
          required,
          sameAsPassword: sameAs(this.fields.newPassword),
        },
      },
    };
  },
  methods: {
    async show(data) {
      return new Promise((resolve) => {
        Object.assign(this, data);
        this.internal.resolve = resolve;
        this.internal.show = true;
      });
    },
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      adminService
        .changePassword(this.fields.currentPassword, this.fields.newPassword)
        .then(() => {
          this.$refs.dialog
            .show({
              title: this.$t('successDialog.title'),
              message: this.$t('changepassword.message'),
              icon: 'thin-check',
            })
            .then(() => {
              this.close();
              if (this.internal.resolve) this.internal.resolve();
            });
        })
        .catch((error) => {
          this.$refs.dialog.show({
            title: this.$t('errorDialog.title'),
            message: ErrorHelper.getErrorMessage(error),
            icon: 'thin-alert',
          });
        });
    },
    close: function() {
      this.internal.show = false;
    },
    onHide() {
      Object.keys(this.fields).forEach((key) => {
        this.fields[key] = null;
      });
      this.v$.$reset();
    },
  },
};
</script>
<style scoped>
.hidden {
  display: none;
}
</style>