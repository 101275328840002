<template>
    <div class="p-grid p-py-1">
        <div v-for="index in parseInt(cols)" :key="index" class="p-col" :class="className">
            <Skeleton class="p-py-2"></Skeleton>
        </div>
    </div>
</template>

<script>
export default {
  name : 'GridSkeleton',
  props: ['cols', 'className'],
  data(){
      return {
      }
  },
  methods: {
  }
}
</script>