<template>
  <div class="container">
    <div class="progress-container">
      <div class="progress" :style="{ width: currentProgress + '%' }"></div>
      <div class="progress-shadow"></div>
      <div v-for="(st, index) in steps" :class="{
        active: step > index,
        completed: multiStep && ((step > index + 1) || (step == index+1 && completed))
      }" :key="index" class="circle">
        {{ index + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WizardSteps",
  tag: "wizard-steps",
  props: {
    "steps": {
      'default': [
        {
          id: 1,
          step: 1,
          //completable: true,
          name: 'step1',
          label: ''
        },
        {
          id: 2,
          step: 2,
          //completable: true,
          name: 'step2',
          label: ''
        },
        {
          id: 3,
          step: 3,
          //completable: true,
          name: 'step3',
          label: ''
        },
      ],
      'type': Array,
    },
    "step": {
      'default': 1,
      'type': Number
    },
    "stepProgress": {
      'default': 0,
      'type': Number
    },
    "completed": {
      'default': false,
      'type': Boolean,
    },
    "multiStep": {
      'default': true,
      'type': Boolean,
    }
  },
  data() {
    return {
      //currentActive: 1,
      progress: 0,
      // steps: [
      //   { circle: 1, id: 0 },
      //   { circle: 2, id: 1 },
      //   { circle: 3, id: 2 },
      //   { circle: 4, id: 3 },
      // ],
    };
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    currentProgress() {
      return (((this.step - 1) * 100) / (this.totalSteps - 1)) + this.currentStepProgress;
    },
    currentStepProgress() {
      return ((this.stepProgress * (100 / (this.totalSteps - 1))) / 100);
    }
  },
  methods: {
    // next: function () {
    //   this.currentActive++;
    // },
    // prev: function () {
    //   this.currentActive--;
    // },
    // visibility: function () {
    //   if (this.currentActive === 1) {
    //     this.prevIsDisabled = true;
    //     this.nextIsDisabled = false;
    //     this.progress = 0;
    //   } else if (this.currentActive === 2) {
    //     this.prevIsDisabled = false;
    //     this.nextIsDisabled = false;
    //     this.progress = 33.33;
    //   } else if (this.currentActive === 3) {
    //     this.prevIsDisabled = false;
    //     this.nextIsDisabled = false;
    //     this.progress = 66.66;
    //   } else {
    //     this.prevIsDisabled = false;
    //     this.nextIsDisabled = true;
    //     this.progress = 100;
    //   }
    // },
    // goToCircle: function (step) {
    //   this.currentActive = step.circle;
    // },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 100%;
}

.progress-container::before {
  content: "";
  background-color: #e0e0e0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
}

.progress {
  background-color: #3498db;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: 2;
  transition: 0.4s ease;
}

.progress-shadow {
  background-color: #e0e0e0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 2px;
  width: 100%;
  z-index: 1;
  transition: 0.4s ease;
}

.circle {
  background-color: #fff;
  color: #999;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #e0e0e0;
  transition: 0.4s ease;
  cursor: pointer;
  z-index: 5;
}

.circle.active {
  border-color: #3498db;
}

.circle.active.completed {
  background-color: #3498db;
  color: #fff;
}
</style>
