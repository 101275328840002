export const apiConfig = {
  baseUrl: '',
  downloadUrl: '',
  reCaptchaSiteKey: '',
  domain : '',
  ipAllowed: false,
  oauthProviders: [],
  externalUrls: [],
  getConfigUrlValue: function(key) {
    let item = this.externalUrls.find((elem) => elem.name == key);
    return (item) ? item.url : null
  }
}