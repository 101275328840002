import { createApp } from 'vue';
import App from './App.vue';
import router, { config as routerConfig } from './router';
import PrimeVue from 'primevue/config';

import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import BlockUI from 'primevue/blockui';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import InlineMessage from 'primevue/inlinemessage';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import ListBox from 'primevue/listbox';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Tag from 'primevue/tag';
import Toolbar from 'primevue/toolbar';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TreeTable from 'primevue/treetable';
import Tooltip from 'primevue/tooltip';
import ConfirmDialog from './components/ConfirmDialog';
import Skeleton from 'primevue/skeleton';

import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import './assets/styles/css/themes/cybele.css';

import { apiConfig } from './config/backend-api';

import axios from 'axios';
import i18n from './lang';
import { store } from './store';
import { authService } from './services/auth.service';
import { adminService } from './services/admin.service';
import Vue3Tour from "vue3-tour";
import { SecurityHelper } from './helpers/SecurityHelper';
import { HBChat } from './helpers/HSHelper';

import { ErrorHelper } from './helpers/ErrorHelper';

import VTooltip from "v-tooltip";

import './assets/styles/css/cybele-vue-tour.css';

import VueGtag from "vue-gtag-next";

const app = createApp(App);
app.use(store);
app.use(i18n);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(Vue3Tour);
app.use(VTooltip);
if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACK.toUpperCase() == "TRUE")
  app.use(VueGtag, { property: {  id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG_ID } });

app.component('AutoComplete', AutoComplete);
app.component('BlockUI', BlockUI);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('InlineMessage', InlineMessage);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('InputSwitch', InputSwitch);
app.component('ListBox', ListBox);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OverlayPanel', OverlayPanel);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Row', Row);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('Tag', Tag);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('TreeTable', TreeTable);
app.component('Skeleton', Skeleton);
app.directive('tooltip', Tooltip);

// async function reloadConfig() {
//   return await axios({
//     url: `${process.env.VUE_APP_API_URL}/config`,
//     method: 'GET',
//     responseType: 'text',
//   })
//     .then((response) => {
//       let data = response.data.data;
//       apiConfig.baseUrl = data.wsurl;
//       apiConfig.downloadUrl = data.downloadUrl;
//       apiConfig.domain = data.domain;
//       apiConfig.ipAllowed = data.ipAllowed !== false;
//       if (apiConfig.baseUrl.endsWith('/')) {
//         apiConfig.baseUrl = apiConfig.baseUrl.substr(0, apiConfig.baseUrl.length - 1);
//       }
//   })
// }

axios.interceptors.request.use(
  function(config) {
    //if (!(store.state.isPing || store.state.isDownload) && !(config.ajaxState == false)) {
    if (!(config.ajaxState == false)) {
      store.commit('ajaxState', true);
    }
    return config;
  },
  function(error) {
    store.commit('ajaxState', false);
    return Promise.reject(error);
  }
);

const __respCodes__ = Object.keys(ErrorHelper).filter(k => k.startsWith("RESPCODE"));

axios.interceptors.response.use(
  function(response) {
    store.commit('ajaxState', false);
    return response;
  },
  async function(error) {
    store.commit('ajaxState', false);
    if (error && error.config && !(error?.config?.networkError == false)) {
      const status = error?.response?.status ?? null;
      const errorCode = error?.response?.data?.error?.code;
      let _knownError = !!__respCodes__.find(t => ErrorHelper[t] == errorCode);
      if (status == null || !_knownError || errorCode == ErrorHelper.RESPCODE_INTERNALSERVERERROR) {
        store.state.networkError = true;
        authService.logout();
      } else {
        if (status === 401) {
          if (errorCode == ErrorHelper.RESPCODE_INVALIDTOKEN) {
            authService.logout();
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

axios({
  url: `${process.env.VUE_APP_API_URL}/config`,
  method: 'GET',
  responseType: 'text',
})
  .then(async (response) => {
    let data = response.data.data;
    try {
      let hubSpotChatConfig = response.data.data.hubSpotChat;
      if (hubSpotChatConfig) {
        HBChat.configure(JSON.parse(hubSpotChatConfig));
      }
    } catch (e) {
      //
    }
    apiConfig.baseUrl = data.wsurl;
    apiConfig.downloadUrl = data.downloadUrl;
    apiConfig.domain = data.domain;
    apiConfig.ipAllowed = data.ipAllowed !== false;
    if (apiConfig.baseUrl.endsWith('/')) {
      apiConfig.baseUrl = apiConfig.baseUrl.substr(0, apiConfig.baseUrl.length - 1);
    }
    if (data.externalUrls) {
      try {
        apiConfig.externalUrls = Array.isArray(data.externalUrls)
          ? data.externalUrls
          : JSON.parse(data.externalUrls);
      } catch (e) {
        apiConfig.externalUrls = [];
      }
    }
    if (data.oauthProviders) apiConfig.oauthProviders = data.oauthProviders;
    //apiConfig.externalUrls.push({ "name": "help", "url": "https://workspaces-docs.cybelesoft.com/" });
    apiConfig.reCaptchaSiteKey = response.data.data.reCaptchaSiteKey;
    store.commit('setPasswordComplexity', JSON.parse(response.data.data.passwordComplexity));

    if (response.data.data.sanitizedBlackList)
      SecurityHelper.sanitizedBlackList = response.data.data.sanitizedBlackList;


    if (response.data.data.sanitizedTagBlackList)
      SecurityHelper.sanitizedTagBlackList = JSON.parse(response.data.data.sanitizedTagBlackList);


    if (response.data.data.validationLengths)
      SecurityHelper.validationLengths = JSON.parse(response.data.data.validationLengths);

    if (store.state.auth.token) {
      try {
        await adminService.refreshUserData();
        router.push(routerConfig.defaultRoute);
      } catch {
        authService.logout();
      }
    }
  })
  .catch(() => {
    store.state.networkError = true;
  }).then(() => {
    app.use(router);
    app.mount('#app');
  });

