<template>
  <div class="sidemenu">
    <div
      :key="name" v-for="(value, name) in visibleOptions"
      class="item" :class="(value.id == activeOption ? 'active': '')"
      :id="value.id" @click="navigateTo(value.id)"
      :data-v-step="`${value.id}`"
    >
      <div class="item-content">
        <span class="icon" :class="value.icon"></span>
        <span class="label">{{value.label}}</span>
      </div>
      <span class="selector"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenuBar",
  data() {
    return {
      activeOption: null
    }
  },
  props: [ "options", "default" ],
  computed: {
    visibleOptions() { return this.options.filter((e) => e.visible) }
  },
  mounted() {
    // if (!this.activeOption && this.default) {
    //   this.navigateTo(this.default);
    // }
  },
  methods: {
    navigateTo(value) {
      let option = this.options.find((e) => { return e.id == value});
      if (option) {
        if (option.action != undefined) {
          this.activeOption = option.id;
          option.action();
        } else if (option.route != undefined) {
          this.$router.push(option.route);
        }
      }
    }
  },
  watch:{
    $route: {
      handler(to) {
        if (to && to.name) {
          let value = to.name;
          let option = this.options.find((e) => { return e.id == value});
          if (option?.route != undefined) {
            this.activeOption = option.id;
          }
        }
      },
      immediate: true,
    }
  }
};
</script>