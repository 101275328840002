'use strict';
export default class PlanHelper {
    static #infoPlans = [{
            id: 1,
            key: 'basic',
            best: false,
            data: {
                price: 11,
                yearlyPrice: 9,
                ranges: {
                    users: 10,
                    brokers: 2,
                    appnodes: 1
                },
                checkFeatures: {
                    'webvpn': 1,
                    'rdp': 1,
                    'oauthsso': 1,
                    'end2end': 1,
                    'filetransfer': 1,
                    'remoteprinting': 1,
                    'accountadmin': 1,
                    'multiadmin': -1,
                    'hotptotp': -1,
                    'mobileapps': -1,
                    'vnc': -1,
                    'samlradius': -1,
                    'adintegration': -1,
                    'privategwy': -1,
                    'wakeonlan': -1,
                },
                support: "emailsupport"
            }
        },
        {
            id: 2,
            key: 'pro',
            previous: 'basic',
            best: true,
            data: {
                price: 15,
                yearlyPrice: 12,
                ranges: {
                    users: 50,
                    brokers: 5,
                    appnodes: 5
                },
                checkFeatures: {
                    'webvpn': 1,
                    'rdp': 1,
                    'oauthsso': 1,
                    'end2end': 1,
                    'filetransfer': 1,
                    'remoteprinting': 1,
                    'accountadmin': 1,
                    'multiadmin': 2,
                    'hotptotp': 2,
                    'mobileapps': 2,
                    'vnc': -1,
                    'samlradius': -1,
                    'adintegration': -1,
                    'privategwy': -1,
                    'wakeonlan': -1,
                },
                support: "emailandphone"
            }
        },
        {
            id: 3,
            key: 'enterprise',
            previous: 'basic & pro',
            best: false,
            data: {
                price: 19,
                yearlyPrice: 15,
                ranges: {
                    users: -1,
                    brokers: -1,
                    appnodes: -5
                },
                checkFeatures: {
                    'webvpn': 1,
                    'rdp': 1,
                    'oauthsso': 1,
                    'end2end': 1,
                    'filetransfer': 1,
                    'remoteprinting': 1,
                    'accountadmin': 1,
                    'multiadmin': 2,
                    'hotptotp': 2,
                    'mobileapps': 2,
                    'vnc': 3,
                    'samlradius': 3,
                    'adintegration': 3,
                    'privategwy': 3,
                    'wakeonlan': 3,
                },
                support: "fullsupport"
            }
        }
    ];
    static #features = [
        'webvpn', 'rdp','oauthsso', 'end2end', 'filetransfer', 'remoteprinting', 'accountadmin', 'multiadmin', 'hotptotp', 'mobileapps', 'vnc', 'samlradius',  'adintegration', 'privategwy', 'wakeonlan'
    ];
    static getPlan(id, dicc) {
        let obj = this.#infoPlans.find((elem) => elem.id == id);
        if (obj) {
            obj = {
                'title': dicc('plans.headers.' + obj.key + '.title'),
                'description': this.#description(obj.key, dicc),
                'bestChoice': obj.best
            };
        } else {
            obj = {
                'title': 'Plan Title ' + id,
                'description': ['plan description'],
                'bestChoice': false
            };
        }
        return obj;
    }
    static getRanges(id, dicc, shortVersion) {
        let obj = this.#infoPlans.find((elem) => elem.id == id).data.ranges;
        let ranges = [];
        if (shortVersion) {
            ranges.push((obj.users!=-1) ? dicc('plans.features.upto', { n: obj.users }) : dicc('plans.features.unlimited'));
            ranges.push((obj.users!=-1) ? dicc('plans.features.upto', { n: obj.brokers }) : dicc('plans.features.unlimited'));
        } else {
            ranges.push((obj.users!=-1) ? dicc('plans.features.uptoitem', {"n": obj.users, "s": dicc("plans.users") }) : dicc('plans.features.unlimited', {"s": dicc("plans.users") }));
            ranges.push((obj.brokers!=-1) ? dicc('plans.features.uptoitem', {"n": obj.brokers, "s": dicc("plans.brokers")}) : dicc('plans.features.unlimited', {"s": dicc("plans.brokers") }));
        }
        return ranges;
    }
    static getRangesDesc(dicc) {
        let items = [];
        let i = 0;
        let key = 'plans.ranges[' + i + "]";
        let value = dicc(key);
        while (key != value) {
            items.push(value);
            i++;
            key = 'plans.ranges[' + i + "]";
            value = dicc(key);
        }
        return items;
    }
    static getFeatures(id, dicc) {
        let obj = this.#infoPlans.find((elem) => elem.id == id);
        let list = {};
        for (let f = 0; f < this.#features.length; f++) {
            let fKey = this.#features[f];
            list[fKey] = {
                "available": obj.data.checkFeatures[fKey] != -1,
                "desc": dicc('plans.features.' + fKey),
                "help":  dicc('plans.help.' + fKey)
            };
        }
        return list;
    }
    static getOwnFeatures(id, dicc) {
        let obj = this.#infoPlans.find((elem) => elem.id == id);
        let list = {};
        for (let f = 0; f < this.#features.length; f++) {
            let fKey = this.#features[f];
            if (obj.data.checkFeatures[fKey]==id)
                list[fKey] = {
                    "desc": dicc('plans.features.' + fKey),
                    "help":  dicc('plans.help.' + fKey)
                };
        }
        return list;
    }
    static ownFeaturesCount(plan) {
        let count = 0;
        for (let feat in plan.data.checkFeatures) {
            if (plan.data.checkFeatures[feat] == plan.id) {
                count++;
            }
        }
        return count;
    }
    static getMaxOwnFeatures() {
        let max = 0;
        for (let p = 0; p < this.#infoPlans.length; p++) {
            max = Math.max(max, this.ownFeaturesCount(this.#infoPlans[p]))
        }
        return max;
    }
    static getAllIn(id, dicc) {
        let output = null;
        let obj = this.#infoPlans.find((elem) => elem.id == id);
        if (obj.previous)
            output = dicc( 'plans.features.allinplus', {"prev": obj.previous }).toUpperCase();
        return output;
    }
    static getFeaturesDesc(dicc) {
        let list = [];
        for (let f = 0; f < this.#features.length; f++) {
            list.push({ "desc": dicc('plans.features.' + this.#features[f]), "help":  dicc('plans.help.' + this.#features[f]) });
        }
        return list;
    }
    static getSupport(id, dicc) {
        let obj = this.#infoPlans.find((elem) => elem.id == id);
        return dicc('plans.features.'+obj.data.support);
    }
    static getSupportDesc(dicc) {
        return dicc('plans.supportLevel');
    }
    static getPrice(id, monthly) {
        let obj = this.#infoPlans.find((elem) => elem.id == id);
        let price = obj.data.price;
        let yearlyPrice = obj.data.yearlyPrice ?? null;
        return (monthly) ? price : (yearlyPrice) ? yearlyPrice : price * 5 / 6;
    }
    static #description(itemkey, dicc) {
        let items = [];
        let i = 0;
        let key = 'plans.headers.' + itemkey + '.description' + "[" + i + "]";
        let value = dicc(key);
        while (key != value) {
            items.push(value);
            i++;
            key = 'plans.headers.' + itemkey + '.description' + "[" + i + "]";
            value = dicc(key);
        }
        return items;
    }
}