<template>
    <div class="compact-list">
        <div v-if="isloading">
            <slot name="loading">
                loading...
            </slot>
        </div>
        <div v-else :class="this.class">
            <div v-if="filtrable">
                <div class="searchbox compact"><i class="thin-magnifier p-mr-1" /><InputText v-model="currentFilter"/></div>
            </div>
            <div v-if="items.length == 0">
                <slot name="empty"></slot>
            </div>
            <div v-if="filtrable && (myItems.length==0) && items.length > 0">
                <slot name="noresults"></slot>
            </div>
            <div class="element-row" :key="item" v-for="item in myItems">
                <div class="inline content">
                    <slot name="content" :item="item">
                    </slot>
                </div>
                <div class="inline actions">
                    <slot name="actions" :item="item">
                        <Button icon="thin-edit" v-tooltip.top="$t('actions.edit')" class="p-button-link" @click="editItem(item)" />
                    </slot>
                    <Button v-if="this.isDeletable(item)" icon="thin-trash" v-tooltip.top="$t('actions.delete')" class="p-button-link" @click="deleteItem(item)" />
                    <b v-else class="p-button p-button-icon-only p-button-link">&nbsp;</b>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompactList",
    tag: "compact-list",
    data() { 
        return {
            currentFilter: this.filter 
        }
    },
    props: {
        'class': {},
        'items': { 'default': [] },
        'selection': { 'default': [] },
        'isloading': { 'default': false },
        'filter': { 'default': "" },
        'filtrable': { 'default': true },
        'itemFilter': { 'default': function() { return true } },
        'deletable': { 'default': null }
    },
    emits: ['edit', 'delete', 'update:filter'],
    watch: {
        "currentFilter": {
            immediate: true,
            handler: function(val) {
                this.$emit('update:filter', val);
            }
        },
    }, 
    computed: {
        myItems: function() {
            return (this.currentFilter) ? this.items.filter((item) => this.itemFilter(item, this.currentFilter)) : this.items;
        }
    },
    methods: {
        /*
        'copyToClipboard': function(data) {
            this.$emit('copy', data);
        },
        */
        'editItem': function(data) {
            this.$emit('edit', data);
        },
        'deleteItem': function(data) {
            this.$emit('delete', data);
        },
        'isDeletable': function(item) {
            return (this.deletable || function() { return true })(item)
        }
    }
};
</script>