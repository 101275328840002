<template>
  <div class="vld-parent">
    <ConfirmDialog ref="confirmDlg"></ConfirmDialog>
    <div class="p-mt-6 p-mr-6 p-ml-6">
      <div class="header-template">
        <div class="p-grid">
          <div class="p-col-6">
            <h1 class="p-mt-0 p-mb-0">{{ $t('users.title') }}</h1>
          </div>
          <div class="p-col-6 p-text-right">
              <Button :label="$t('users.newUser')" icon="thin-plus" class="p-pl-3 p-pr-4" @click="newUser" />
          </div>
        </div>
      </div>
      <resizer @resize="onResize"></resizer>
      <div v-if="compactMode == true">
          <compact-list class="mobile-table"
            :items="users"
            :isloading="loadingUsers"
            :filtrable="true" :deletable="isDeletable"
            v-model:filter="filters.global.value" :itemFilter="isValid" @edit="editUser" @delete="deleteUser"
            >
          <template #loading>
            <GridSkeleton v-for="inx in 2" :key="inx" cols="3"></GridSkeleton>
            <GridSkeleton v-for="inx in 5" :key="inx" cols="3" :className="`p-opacity-${6 - inx}`"></GridSkeleton>
          </template>
          <template #empty>
            <div class="p-d-flex p-jc-center p-py-4">
              <div class="p-text-center p-lineheight-2">
                <span>{{ $t('users.no_users_found') }}</span>
              </div>
            </div>
          </template>
          <template #noresults>
            <div class="p-d-flex p-jc-center p-py-4">
              <div class="p-text-center p-lineheight-2">
                <span>{{ $t('users.no_users_match') }}</span>
              </div>
            </div>
          </template>
          <template #content="slotProps">
            <div><b>{{slotProps.item.name}}</b> ({{userRoleName(slotProps.item.role)}} - {{userStatusName(slotProps.item.status)}})</div>
            <div>{{slotProps.item.id}}</div>
          </template>
        </compact-list>
      </div>
      <div v-else class="p-grid">
        <div class="p-col-12">
          <div class="p-mt-4">
            <DataTable
              ref="dt"
              :value="users"
              dataKey="id"
              v-model:filters="filters"
              filterDisplay="menu"
              :loading="loadingUsers"
              loadingIcon=""
              :paginator="true"
              :rows="10"
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
              :rowsPerPageOptions="[5, 10, 20, 50]"
              currentPageReportTemplate="{first} - {last} of {totalRecords}"
              :globalFilterFields="['id', 'name']"
              responsiveLayout="scroll"
              sortField="name"
              :sortOrder="1"
            >
              <template #header>
              <div class="p-d-flex p-jc-between p-ai-center">
                <div class="searchbox p-d-flex">
                  <i class="thin-magnifier p-mr-1" />
                  <InputText v-model="filters['global'].value" :placeholder="$t('datatable.search')" />
                </div>
              </div>
              </template>
              <template #loading>
                <div class="loading">
                  <GridSkeleton v-for="inx in 2" :key="inx" cols="5"></GridSkeleton>
                  <GridSkeleton v-for="inx in 5" :key="inx" cols="5" :className="`p-opacity-${6 - inx}`"></GridSkeleton>
                </div>
              </template>
              <template #empty>
                <div class="p-d-flex p-jc-center p-py-4">
                  <div class="p-text-center p-lineheight-2">
                    <span>{{ $t('users.no_users_found') }}</span>
                  </div>
                </div>
              </template>

              <Column field="name" :header="$t('users.name')" :sortable="true"></Column>
              <Column field="id" :header="$t('users.email')" :sortable="true"></Column>
              <Column field="roleName" :header="$t('users.role')" :sortable="true"></Column>
              <Column field="status" :header="$t('users.status')" :sortable="true">
                <template #body="slotProps">
                  <span>{{ userStatusName(slotProps.data.status)}}</span>
                </template>
              </Column>
              <Column :header="$t('users.actions')">
                <template #body="slotProps">
                  <Button
                    v-if="showEditButton(slotProps.data)"
                    v-tooltip.top="$t('users.edit')"
                    icon="thin-edit"
                    class="p-button-link"
                    @click="editUser(slotProps.data)"
                  />
                  <Button
                    v-if="showDeleteButton(slotProps.data)"
                    v-tooltip.top="$t('users.delete')"
                    icon="thin-trash"
                    class="p-button-link"
                    @click="deleteUser(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- New user Dialog -->
  <Dialog class="p-fluid narrow" v-model:visible="displayNewUserDialog" :header="$t('users.dialogTitleNew')" :modal="true">
    <!--First Name-->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label>{{ $t("users.firstName") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <InputText v-model="user.firstName" type="text" name="firstname" autofocus :maxLength="getMaxLength('firstName')"/>
        <field-error :value="v$.user.firstName" />
      </div>
    </div>
    <!--Last Name-->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label>{{ $t("users.lastName") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <InputText v-model="user.lastName" type="text" name="lastName" :maxLength="getMaxLength('lastName')" />
        <field-error :value="v$.user.lastName" />
      </div>
    </div>

    <!--Role-->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label>{{ $t("users.role") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <Dropdown
          id="role"
          v-model="user.role"
          :options="rolesEdit"
          optionLabel="name"
          optionValue="id"
          :filter="true"
        />
        <field-error :value="v$.user.role" />
      </div>
    </div>

    <!--User directory Id-->
    <!-- <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label>{{ $t("users.activeDirectoryId") }}</label>
      </div>
      <div class="p-col p-field">
        <InputText v-model="user.activeDirectoryId" type="text" name="activedirectoryid" autofocus />
        <field-error :value="v$.user.activeDirectoryId" />
      </div>
    </div> -->

    <!--Email -->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label>{{ $t("users.email") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <InputText v-model="user.email" type="mail" name="email" autofocus />
        <field-error :value="v$.user.email" />
      </div>
    </div>
    <!--Confirm Email -->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label>{{ $t("users.confirmEmail") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <InputText
          v-model="user.emailConfirmation"
          type="mail"
          name="emailConfirmation"
          autofocus
        />
        <field-error :value="v$.user.emailConfirmation" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('common.createButton')" class="p-button-primary" @click.prevent="createUser" />
      <Button
        :label="$t('common.cancelButton')"
        @click="closeNewUserDialog"
        class="p-button-secondary"
      />
    </template>
  </Dialog>
  <!-- End New user Dialog -->

  <!-- Edit user Dialog -->
  <Dialog
    class="p-fluid narrow"
    v-model:visible="displayEditUserDialog"
    :header="$t('users.dialogTitleEdit')"
    :modal="true"
  >
    <!--First Name-->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
          <label for="firstname">{{ $t("users.firstName") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <InputText v-model="user.firstName" type="text" name="firstname" autofocus :maxLength="getMaxLength('firstname')"/>
        <field-error :value="v$.user.firstName" />
      </div>
    </div>
    <!--Last Name-->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
          <label for="lastName">{{ $t("users.lastName") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <InputText v-model="user.lastName" type="text" name="lastName" :maxLength="getMaxLength('lastName')" />
        <field-error :value="v$.user.lastName" />
      </div>
    </div>

    <!--Role-->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
          <label for="role">{{ $t("users.role") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <Dropdown
          id="role"
          v-model="user.role"
          :options="currentRoles(user)"
          optionLabel="name"
          optionValue="id"
          :filter="true"
          :placeholder="$t('users.role')"
        />
        <field-error :value="v$.user.role" />
      </div>
    </div>

    <!--User directory Id-->
    <!-- <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label for="userIdAD">{{ $t("users.activeDirectoryId") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <InputText id="userIdAD" v-model="user.activeDirectoryId" type="text" name="activedirectoryid" autofocus />
        <field-error :value="v$.user.activeDirectoryId" />
      </div>
    </div> -->

    <!-- Status-->
    <div class="p-fluid p-grid">
      <div class="p-col p-pb-0">
         <label for="status">{{ $t("users.status") }}</label>
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col p-field">
        <Dropdown
          id="status"
          v-model="user.status"
          :options="status"
          optionLabel="name"
          optionValue="id"
          :optionDisabled="(op) => checkStatusByProfile(op, user)"
          :filter="true"
          :placeholder="$t('users.status')"
        />
        <field-error :value="v$.user.status" />
      </div>
    </div>

    <template #footer>
      <div class="p-grid">
        <div class="p-col-4 text-left">
          <Button ref="resetBtn" :label="$t('users.resetPasswordButton')" @click="resetPassword" />
        </div>
        <div class="p-col">
          <Button ref="updateBtn" :label="$t('common.updateButton')" class="p-button-primary" @click.prevent="updateUser" />
          <Button ref="cancelBtn" :label="$t('common.cancelButton')" @click="closeEditUserDialog" class="p-button-secondary" />
        </div>
      </div>
    </template>
  </Dialog>
  <!-- End Edit user Dialog -->
  <CommonDialog ref="dialog"></CommonDialog>
</template>

<script>
import { userRole, userStatus } from '../services/auth.service';
import { adminService } from '../services/admin.service';
import { useVuelidate } from '@vuelidate/core';
import { email, required, sameAs, minLength, maxLength} from '@vuelidate/validators';
import { ErrorHelper } from '../helpers/ErrorHelper';
import { FilterMatchMode } from 'primevue/api';
import FieldError from '../components/FieldError.vue';
import CommonDialog from '../components/CommonDialog.vue';
import GridSkeleton from '../components/GridSkeleton.vue';
import {SecurityHelper} from '../helpers/SecurityHelper';
import CompactList from '../components/CompactList.vue';
import Resizer from '../components/Resizer.vue';

const COMPACT_LIMIT = 800;

export default {
  name: 'Users',
  setup: () => ({ v$: useVuelidate() }),
  components: {
    FieldError,
    GridSkeleton,
    Resizer,
    CompactList,
    CommonDialog
  },
  data() {
    return {
      roles: [],
      rolesEdit: [],
      status: [],
      statusEdit: [],
      message: '',
      displayNewUserDialog: false,
      displayEditUserDialog: false,
      users: [],
      loadingUsers: true,
      selectedusers: [],
      user: {
        firstName: '',
        lastName: '',
        role: 0,
        activeDirectoryId: '',
        email: '',
        emailConfirmation: '',
        status: 0,
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      editUserDialog:{
          idx: 2,
          buttons:["resetBtn", "updateBtn", "cancelBtn"]
      },
      width: window.innerWidth,
      getMaxLength: (property) => { return SecurityHelper.getPropertyLength('users', property, true) },
      getLengthVal: (property) => { return SecurityHelper.getPropertyLength('users', property, false) }
    };
  },
  computed: {
    compactMode() {
      return this.width <= COMPACT_LIMIT;
    },
  },
  validations() {
    return {
      user: {
        firstName: { required,
          minLength: minLength(this.getLengthVal('firstName').minLength),
          maxLength: maxLength(this.getLengthVal('firstName').maxLength),
        },
        lastName: { required,
          minLength: minLength(this.getLengthVal('lastName').minLength),
          maxLength: maxLength(this.getLengthVal('lastName').maxLength),
        },
        role: { required },
        email: { required, email },
        emailConfirmation: { required, sameAsEmail: sameAs(this.user.email) },
        status: { required },
      },
    };
  },
  mounted() {
    this.roles = [
      { id: userRole.USER, name: this.$t('users.roleNameUser') },
      { id: userRole.ADMIN, name: this.$t('users.roleNameAdmin') },
      { id: userRole.OWNER, name: this.$t('users.roleNameOwner') },
    ];
    this.rolesEdit = [
      { id: userRole.USER, name: this.$t('users.roleNameUser') },
      { id: userRole.ADMIN, name: this.$t('users.roleNameAdmin') },
    ];
    this.status = [
      { id: userStatus.PENDING, name: this.$t('users.statusPending') },
      { id: userStatus.ACTIVE, name: this.$t('users.statusActive') },
      { id: userStatus.INACTIVE, name: this.$t('users.statusInactive') },
      { id: userStatus.LOCKED, name: this.$t('users.statusLocked') },
    ];
    this.passwordComplexity = this.$store.state.passwordComplexity;
    this.getUsers({ config: { ajaxOptions: { ajaxState: false } } });
  },
  methods: {
    currentRoles(user) {
      return (user.role === userRole.OWNER) ?
        [ { id: userRole.OWNER, name: this.$t('users.roleNameOwner') } ]
        : this.rolesEdit;
    },
    checkStatusByProfile(op,user) {
      return user.role == userRole.OWNER && op.id != userStatus.ACTIVE;
    },
    currentStatus(user) {
      return (user.role === userRole.OWNER) ?
        [ { id: userRole.OWNER, name: this.$t('users.roleNameOwner') } ]
        : this.rolesEdit;
    },
    showEditButton(user) {
      if (this.$store.state.auth.user.role === userRole.OWNER) {
        return true
      } else {
        return (user.role !== userRole.OWNER);
      }
    },
    showDeleteButton(user) {
      return  (user.role !== userRole.OWNER) && (user.id !== this.$store.state.auth.user.email);
    },
    userRoleName(role) {
      return this.roles.find(element => element.id === role).name;
    },
    userStatusName(status) {
      return this.status.find(element => element.id === status).name;
    },
    resetPassword() {
      adminService
        .forgotPassword(this.$store.state.auth.user.accountId, this.user.email)
        .then(() => {
          this.showMessage(this.$t("users.resetPassword.message"), () => { this.displaySuccessDialog = true });
        })
        .catch((error) => {
          this.showError(ErrorHelper.getErrorMessage(error));
        });
    },
    closeNewUserDialog() {
      this.displayNewUserDialog = false;
    },
    closeEditUserDialog() {
      this.displayEditUserDialog = false;
      //window.removeEventListener("keyup",this.setButtonFocus);
    },
    editUser(editUser) {
      this.v$.$reset();
      this.user.firstName = editUser.firstName;
      this.user.lastName = editUser.lastName;
      this.user.role = editUser.role;
      this.user.activeDirectoryId = editUser.activeDirectoryId;
      this.user.email = editUser.id;
      this.user.emailConfirmation = editUser.id;
      this.user.status = editUser.status;
      this.displayEditUserDialog = true;
      //window.addEventListener("keyup",this.setButtonFocus);
    },
    newUser() {
      this.v$.$reset();
      this.user.firstName = '';
      this.user.lastName = '';
      /*
      if (this.$store.state.auth.user.role === userRole.OWNER)
        { this.user.role = userRole.ADMIN }
      else
        { this.user.role = userRole.USER }
      */
      this.user.role = userRole.USER;
      this.user.activeDirectoryId = '';
      this.user.email = '';
      this.user.emailConfirmation = '';
      this.displayNewUserDialog = true;
    },
    getUsers({ config = {} } = {}) {
      adminService
        .getUsers({ config })
        .then((response) => {
          let r = { response };
          let users = r.response.data.data.members;
          users = users.sort((a, b) => (a.name < b.name) ? -1 : 1);
          this.users = users.map(m => {
            return {
              id: m.id,
              name: m.name,
              firstName: m.data.firstName,
              lastName: m.data.lastName,
              email: m.data.email,
              roleName: this.userRoleName(m.level),
              role: m.level,
              activeDirectoryId: m.data.activeDirectoryId,
              status: m.status,
            }
          });
          this.loadingUsers = false;
        })
        .catch((error) => {
          this.showError(ErrorHelper.getErrorMessage(error));
        });
    },
    sanitize() {
      this.user.firstName = SecurityHelper.sanitizeString(this.user.firstName);
      this.user.lastName = SecurityHelper.sanitizeString(this.user.lastName);
      this.user.activeDirectoryId = SecurityHelper.sanitizeString(this.user.activeDirectoryId);
    },
    createUser() {
      this.sanitize();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        adminService
          .createUser(this.user)
          .then(() => {
            this.closeNewUserDialog();
            this.getUsers();
          })
          .catch((error) => {
            this.showError(ErrorHelper.getErrorMessage(error));
          });
      }
    },
    updateUser() {
      this.sanitize();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        adminService
          .updateUser(this.user)
          .then(() => {
            this.closeEditUserDialog();
            this.getUsers();
          })
          .catch((error) => {
            this.showError(ErrorHelper.getErrorMessage(error));
          });
      }
    },
    deleteUser(user) {
      this.$refs.confirmDlg.show({
        title: this.$t("users.deleteUser.title"),
        message: this.$t("users.deleteUser.message"),
        icon: 'thin-question',
        acceptClass: 'p-button-danger'
      }).then(action =>{
        if (action === "ok"){
            adminService
            .deleteUser(user.id)
            .then(() => {
              this.getUsers();
            })
            .catch((error) => {
              this.showError(ErrorHelper.getErrorMessage(error));
            });
        }
      });
    },
    isValid(item, filterValue) {
      return (item.name.toLowerCase().indexOf(filterValue.toLowerCase()) != -1 || item.id.toLowerCase().indexOf(filterValue.toLowerCase()) != -1);
    },
    isDeletable(item) {
      return item.role != userRole.OWNER;
    },
    onResize({width}) {
      this.width = width;
    },
    showMessage(msg, callback) {
      this.$refs.dialog.show({
        title: this.$t('successDialog.title'),
        message: msg,
        icon: 'thin-check',
      }).then(() => {
        if (callback) callback();
      });
    },
    showError(errMsg, callback) {
      this.$refs.dialog.show({
        title: this.$t('errorDialog.title'),
        message: errMsg,
        icon: 'thin-cancel error',
      }).then(() => {
        if (callback) callback();
      });
    }
  }
};
</script>