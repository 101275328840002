<template>
  <top-bar />
  <side-menu-bar :options="options" :default="defaultOptions" />
  <side-page-content><router-view /></side-page-content>
  <CommonDialog ref="cDialog"></CommonDialog>
  <TourDialog ref="tourDialog" @start="startTour" @skip="onTourSkipped"></TourDialog>
  <Toast position="bottom-left" group="tmsgs" />
  <Toast position="bottom-left" group="callToActionMsgs">
    <template #message="slotProps">
      <div class="flex flex-column">
          <div class="text-center p-mt-1">
              <h4>{{slotProps.message.summary}}</h4>
              <p>{{slotProps.message.detail}}</p>
          </div>
          <div class="p-grid p-fluid mt-1">
              <div class="p-col-10 p-offset-1">
                  <Button class="p-button-black" :label="slotProps.message.callToAction" @click="slotProps.message.callToActionCallback" />
              </div>
          </div>
      </div>
    </template>
  </Toast>
  <v-tour name="adminTour" :steps="tour.steps" :callbacks="tourCallbacks"></v-tour>
  <loading v-model:active="isLoading" loader="dots" color="#00b1fc" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import TopBar from '../components/Topbar.vue';
import SideMenuBar from '../components/SideMenuBar.vue';
import SidePageContent from '../components/SidePageContent.vue';
import Loading from 'vue-loading-overlay';
import CommonDialog from "../components/CommonDialog.vue";
import TourDialog from '../components/TourDialog.vue';

import 'vue-loading-overlay/dist/vue-loading.css';
import roles, { hasPermissions } from '../enums/roles';
import { adminService } from '../services/admin.service';
import { mapState, mapGetters, mapActions } from 'vuex';
import { HBChat } from '../helpers/HSHelper';
import { notificationsService } from '../services/notifications.service'

export default {
  name: 'AdminLayout',
  components: {
    TopBar,
    SideMenuBar,
    SidePageContent,
    Loading,
    CommonDialog,
    TourDialog
  },
  data() {
    return {
      defaultOptions: 'workspaces',
      visibleAccount: true,
      tourCallbacks: {
        onFinish: this.onTourFinish,
        onSkip: this.onTourSkipped
      }
    };
  },
  methods: {
    ...mapActions({
      startTour: "tour/startTour",
      stopTour: "tour/stopTour",
    }),
    onTourFinish() {
      this.setTourDone();
    },
    onTourSkipped() {
      this.setTourDone();
    },
    setTourDone() {
      adminService.setUserTour(true, { config: { ajaxOptions: { ajaxState: false } } });
    },
    reactivateTour() {
      adminService.setUserTour(false, { config: { ajaxOptions: { ajaxState: false } } });
    },
    showTour() {
      this.$tours['adminTour'].start();
    },
    showTourWelcome() {
      this.$refs.tourDialog.show();
    },
    checkNotifications() {
      if (this.$store.state.subscription.daysLeft <= 0) {
          notificationsService.showExpiredPlanNotification({ ctx: this });
      }
      if (this.$store.state.notifications.onLogin) {
        if (this.$store.state.account.inTrialMode && this.$store.state.subscription.daysLeft > 0) {
          notificationsService.showNotificationToast({
            summary: this.$t('trialMessages.title'),
            detail: (this.$store.state.subscription.daysLeft < 1) ? this.$t('trialMessages.daysLeftToday') : this.$t('trialMessages.daysLeft', {
              daysTrialLeft: Math.trunc(this.$store.state.subscription.daysLeft)
            })
          }, { ctx: this });
        }
        this.$store.commit('setNotificationsOnLogin', false);
      }
    }
  },
  mounted() {
    this.startTour();
    this.checkNotifications();
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.ajaxState && !this.$store.state.isPing && !this.$store.state.isDownload
      );
    },
    ...mapState({
      HSChatLoaded: state => state.hubSpotChatConfig.loaded,
      downloadBackground: state => state.installerDownload.background,
      downloadCompleted: state => state.installerDownload.completed,
      downloadError: state => state.installerDownload.error,
      downloadErrorMsg: state => state.installerDownload.errorMsg,
      tour: state => state.tour,
      user: state => state.auth.user,
      tourDone: state => state.auth.user.endTour,
      workspaceWizard: (state) => state.account.workspaceWizard,
    }),
    ...mapState('tour', {
      steps: (state) => state.steps,
    }),
    ...mapGetters({
      options: 'menuOptions',
    }),
  },
  watch: {
    HSChatLoaded: {
      immediate: true,
      handler() {
        HBChat.load();
      }
    },
    downloadCompleted(val) {
      if (val === true) {
        if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACK.toUpperCase() == "TRUE")
          this.$gtag.event('action', { 'event_category' : 'download', 'event_label' : 'download installer complete' });

        if (!this.downloadBackground) {
          this.$refs.cDialog.show({
            title: this.$t('downloadInstaller.downloadSuccesful.title'),
            message: this.$t('downloadInstaller.downloadSuccesful.message'),
            icon: 'thin-alert',
          });
        }
      }
    },
    downloadError(val) {
      if (val === true) {
        if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACK.toUpperCase() == "TRUE")
          this.$gtag.event('action', { 'event_category' : 'download', 'event_label' : 'download installer error' });

        if (!this.downloadBackground) {
          this.$refs.cDialog.show({
            title: this.$t('downloadInstaller.downloadError.title'),
            message: this.$t('downloadInstaller.downloadError.message'),
            icon: 'thin-alert',
          });
        }
      }
    },
    "tour.started": function (val) {
      if (val) {
        if (!this.tourDone && hasPermissions(this.user.role, roles.ADMIN) && this.workspaceWizard) {
          if (this.user.role === roles.OWNER) this.showTour();
          else this.showTourWelcome();
        } else {
          this.stopTour();
        }
      }
    }
  },
};
</script>
