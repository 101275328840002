<template>
  <div v-if="loading" class="p-col-12 p-p-0">
    <div class="p-grid p-fluid">
      <div class="p-col-8">
        <Skeleton height="2rem" width="25rem" style="max-width:66%"></Skeleton>
      </div>
      <div class="p-col-4 p-text-right">
        <Skeleton height="2rem" width="12rem" style="float:right;max-width:33%"></Skeleton>
      </div>
    </div>
    <card-skeleton class="p-mt-3"></card-skeleton>
  </div>
  <div v-else class="p-col-12 p-p-0">
    <div class="p-grid p-fluid">
      <div class="p-col-8">
        <h4>{{ $t("account.accountInfoTitle") }}</h4>
      </div>
      <div class="p-col-4 p-text-right align-text-bottom">
        <span class="thin-edit action nowrap" @click="edit()">&nbsp;{{$t("account.edit")}}</span>
      </div>
    </div>
    <div v-if="compact" class="p-col-12 p-mt-0 p-p-3" :class="{ 'capsule': !loading }">
        <div class="p-pb-1"><b>{{ data.companyName }}</b></div>
        <!-- <div class="p-pb-1">{{ data.email ?? $t('common.noemail') }}</div> -->
        <div class="p-pb-1">{{ data.phone ?? $t('common.nophone') }}</div>
        <div class="p-pb-1">{{ fullAddress }}</div>
    </div>
    <div v-else class="p-col-12 p-mt-1 p-p-3" :class="{ 'capsule': !loading }">
        <div class="p-pb-1 strong p-mr-2">{{ $t("account.companyName") }}: <b>{{ data.companyName }}</b></div>
        <div class="p-pb-1">{{ $t("account.email") }}: {{ data.email ?? $t('common.noemail') }}</div>
        <div class="p-pb-1">{{ $t("account.phone") }}: {{ data.phone ?? $t('common.nophone') }}</div>
        <div class="p-pb-1">{{ $t("account.address") }}: {{ data.address ?? '-' }}</div>
        <div class="p-pb-1">{{ $t("account.city") }}: {{ data.city ?? '-' }}</div>
        <div class="p-pb-1">{{ $t("account.state") }}: {{ data.state ?? '-' }}</div>
        <div class="p-pb-1">{{ $t("account.postalCode") }}: {{ data.postalCode ?? '-' }}</div>
        <div class="p-pb-1">{{ $t("account.country") }}: {{ countryName }}</div>
    </div>
    <!-- <Card class="p-mt-3">
      <template #header></template>
      <template #content>
        <span class="strong p-mr-2">Company Name: </span>
        {{ data.companyName }} <br />
        <span class="strong p-mr-2">Email: </span>
        {{ data.email ?? '-' }} <br />
        <span class="strong p-mr-2">Phone: </span>
        {{ data.phone ?? '-' }} <br />
        <span class="strong p-mr-2">Address: </span>
        {{ data.address ?? '-' }} <br />
        <span class="strong p-mr-2">City: </span>
        {{ data.city ?? '-' }} <br />
        <span class="strong p-mr-2">State: </span>
        {{ data.state ?? '-' }} <br />
        <span class="strong p-mr-2">Postal Code: </span>
        {{ data.postalCode ?? '-' }} <br />
        <span class="strong p-mr-2">Country: </span>
        {{ data.country ?? '-' }} <br />
      </template>
    </Card> -->
  </div>
  <!-- Account Information-->
  <CustomDialog
    class="p-fluid medium"
    ref="dialog"
  >
    <template #header>
      <span>{{ $t('account.accountInfoTitle') }}</span>
    </template>
    <template #content>
      <!-- companyName-->
      <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="name">{{ $t("account.companyName") }}</label>
          <InputText
            v-model="fields.companyName"
            type="text"
            name="name"
            autofocus
          />
          <field-error :value="v$.fields.companyName" />
        </div>
      </div>
      <!-- Email -->
      <!-- <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="email">{{ $t("account.email") }}</label>
          <InputText v-model="fields.email" type="text" name="email" />
          <field-error :value="v$.fields.email" />
        </div>
      </div> -->
      <!-- phone -->
      <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="phone">{{ $t("account.phone") }}</label>
          <InputText v-model="fields.phone" type="text" name="phone" />
          <field-error :value="v$.fields.phone" />
        </div>
      </div>
      <!-- address -->
      <!-- <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="address">{{ $t("account.address") }}</label>
          <InputText v-model="fields.address" type="text" name="address" />
          <field-error :value="v$.fields.address" />
        </div>
      </div> -->
      <!-- city -->
      <!-- <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="city">{{ $t("account.city") }}</label>
          <InputText v-model="fields.city" type="text" name="city" />
          <field-error :value="v$.fields.city" />
        </div>
      </div> -->
      <!-- state -->
      <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="state">{{ $t("account.state") }}</label>
          <InputText v-model="fields.state" type="text" name="state" />
          <field-error :value="v$.fields.state" />
        </div>
      </div>
      <!-- country -->
      <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="country">{{ $t("account.country") }}</label>
          <Dropdown
            id="country"
            v-model="fields.country"
            :options="countries"
            optionLabel="name"
            optionValue="code"
            modelValue="fields.country"
            :filter="true"
            :placeholder="$t('account.country')"
          />
          <field-error :value="v$.fields.country" />
        </div>
      </div>
      <!-- zipcode  -->
      <!-- <div class="p-fluid p-grid">
        <div class="p-col p-field">
          <label for="postalcode">{{ $t("account.postalCode") }}</label>
          <InputText
            v-model="fields.postalCode"
            type="text"
            name="postalcode"
          />
          <field-error :value="v$.fields.postalCode" />
        </div>
      </div> -->
    </template>
    <template #footer="slotProps">
      <div class="p-grid">
        <div class="p-col">
          <Button
            :label="$t('account.updateButton')"
            class="p-button-primary"
            @click.prevent="saveInfo(slotProps.emit)"
          />
          <Button
            :label="$t('account.cancelButton')"
            @click.prevent="slotProps.emit({status: 'cancel'}, false)"
            class="p-button-secondary"
            tabindex="-1"
          />
        </div>
      </div>
    </template>
  </CustomDialog>
  <CommonDialog ref="cDialog"></CommonDialog>
</template>
<script>
import CustomDialog from '../components/CustomDialog.vue';
import {
  //email,
  required
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FieldError from '../components/FieldError.vue';
import { ErrorHelper } from "../helpers/ErrorHelper";
import { stripeService } from "../services/stripe.service";
import CountriesHelper from "../helpers/CountriesHelper";
import CommonDialog from '../components/CommonDialog.vue';
import CardSkeleton from '../components/skeletons/CardSkeleton.vue';

export default {
  name : 'AccountCard',
  props: {
    data: {
      default: {}
    },
    loading: {
      default: true,
    },
    compact: {
      default: false,
    }
  },
  components: { CustomDialog, FieldError, CommonDialog, CardSkeleton },
  emits: ["updated"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      fields: {
        companyName: '',
        // email: '',
        // address: '',
        // city: '',
        // phone: '',
        state: '',
        postalCode: '',
        country: '',
      },
      countries: [],
    }
  },
  validations() {
    return {
      fields: {
        companyName: {
          required,
        },
        phone: {
          required,
        },
        // email: {
        //   email,
        //   required,
        // },
        // address: {
        //   required,
        // },
        // city: {
        //   required,
        // },
        state: {
          required,
        },
        // postalCode: {
        //   required,
        // },
        country: {
          required,
        },
      },
    };
  },
  computed: {
    countryName() {
      let output = "-";
      if (this.data && this.data.country) {
        output = ((this.countries) ? this.countries.find((country) => this.data.country == country.code)?.name : this.data.country) ?? this.data.country;
      }
      return output;
    },
    fullAddress() {
      //this.data.address + ' - ' + this.data.city + " - " + this.data.state + ((this.data.postalCode) ? " (" + this.data.postalCode + ")" : "") + " - " + this.data.country
      return this.data.state + " - " + this.countryName;
    },
  },
  created() {
    this.countriesHelper = new CountriesHelper();
  },
  mounted() {
    this.countriesHelper.getCountries().then((data) => (this.countries = data));
  },
  methods: {
    edit() {
      this.fields = { ...this.data };
      this.$refs.dialog.show().then(res => {
        if (res.status === 'ok') {
          this.$emit('updated', res.data);
        }
      });
    },
    saveInfo(emit) {
      const _doValidations = true;
      if (_doValidations) {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
      }
      stripeService
      .updateAccountInformation(this.fields)
      .then((_res) => {
        //Response should have the data updated
        //_res;
        emit({ status: 'ok', data: { ...this.fields } }, false);
      })
      .catch((error) => {
        let errMsg = ErrorHelper.getErrorMessage(error);
        this.$refs.cDialog.show({
          title: this.$t('errorDialog.title'),
          message: errMsg,
          icon: 'thin-cancel error',
        }).then(() => {});
        return;
      });
    }
  }
}
</script>