<template>
  <Dialog
    :modal="true"
    v-model:visible="internal.show"
    :header="false"
    :showHeader="false"
    :style="{ width: '48rem' }"
    :closeOnEscape="false"
    class="setup-wizard"
    :closable="false"
    @hide="setupHidden"
    >
    <div v-show="showStepIndicator" class="p-col-12 p-mt-2">
      <div class="p-offset-3 p-col-6 p-offset-3">
        <wizard-steps :step-progress="stepProgress" :step="stepIndicator" :completed="stepIndicatorCompleted" :multiStep="true" ></wizard-steps>
      </div>
    </div>
    <div v-if="stepAlias == setupWizardSteps.WELCOME">
      <Card :style="{'box-shadow': '0 0 0 0'}">
          <template #title>
            <div class="grid text-center">
              <h1 class="bold-600">
                {{$t(`workspaces.setupWizard.WELCOME.${$mode}.title`)}}
              </h1>
            </div>
          </template>
          <template #content>
            <div class="p-grid p-text-center">
              <div class="p-col-12">
                <i18n-t :keypath='`workspaces.setupWizard.WELCOME.${$mode}.message`' tag="h4" class="p-text-light p-text-spaced">
                  <template #supportedPlatform>
                    <span class="bold-600">{{ $t('workspaces.setupWizard.WELCOME.supportedPlatform') }}</span>
                  </template>
                </i18n-t>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="grid text-center" v-if="isWindows">
              <div class="col-12">
                <Button
                  class="p-button-lg"
                  @click="step1_downloadInstaller"
                  :label="$t('workspaces.setupWizard.WELCOME.callToAction')"
                />
              </div>
              <div class="col-12">
                <Button
                  class="p-button-link p-button-sm p-mt-2 p-color-gray"
                  @click="step1_tryLater"
                  :label="$t('workspaces.setupWizard.WELCOME.tryLater')"
                />
              </div>
            </div>
            <div class="grid text-center" v-if="!isWindows">
              <div class="col-12">
                <Button
                  class="p-button-lg"
                  @click="step1_tryLater"
                  :label="$t('workspaces.setupWizard.WELCOME.close')"
                />
              </div>
            </div>
          </template>
      </Card>
    </div>
    <div v-if="stepAlias == setupWizardSteps.DOWNLOAD">
      <Card :style="{'box-shadow': '0 0 0 0'}">
          <template #title>
            <div class="grid text-center">
              <h1 class="bold-600">{{$t("workspaces.setupWizard.DOWNLOAD.title")}}</h1>
            </div>
          </template>
          <template #content>
            <div class="p-grid p-text-center">
              <div class="p-col-12">
                <h4 class="p-text-light p-text-spaced">
                  {{$t("workspaces.setupWizard.DOWNLOAD.message")}}
                </h4>
              </div>
              <div class="p-col-12 p-mt-4">
                <downloader
                  style="width:100%"
                  :progress="progressValue"
                  :downloading="downloading"
                  :value="$t('services.downloadButton')"
                  :cancelable="false"
                />
              </div>
            </div>
          </template>
      </Card>
    </div>
    <div v-if="stepAlias == setupWizardSteps.OPEN_INSTALLER">
      <Card :style="{'box-shadow': '0 0 0 0'}">
        <template #title>
          <div class="grid text-center">
            <h1 class="bold-600">{{$t("workspaces.setupWizard.OPEN_INSTALLER.title")}}</h1>
          </div>
        </template>
        <template #content>
          <div class="p-grid p-text-center">
            <div class="p-col-12">
              <h4 class="p-text-light p-text-spaced">
                {{$t('workspaces.setupWizard.OPEN_INSTALLER.message1')}}<br>
                {{$t('workspaces.setupWizard.OPEN_INSTALLER.message2')}}<br>
                {{$t('workspaces.setupWizard.OPEN_INSTALLER.message3')}}
              </h4>
            </div>
            <div class="p-col-12 p-text-center p-mt-4">
              <progress-spinner style="width:50px;height:50px" strokeWidth="2"/>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div v-if="stepAlias == setupWizardSteps.LOGIN">
      <Card :style="{'box-shadow': '0 0 0 0'}">
          <template #title>
            <div class="grid text-center">
              <h1 class="bold-600">{{$t("workspaces.setupWizard.LOGIN.title")}}</h1>
            </div>
          </template>
          <template #content>
            <div class="p-grid p-text-center">
              <div class="p-col-12">
                <h4 class="p-text-light p-text-spaced"></h4>
              </div>
              <div class="p-col-12 p-text-center p-mt-4">
                <progress-spinner style="width:50px;height:50px" strokeWidth="2"/>
              </div>
            </div>
          </template>
      </Card>
    </div>
    <div v-if="stepAlias == setupWizardSteps.WORKSPACE_SETUP">
      <Card :style="{'box-shadow': '0 0 0 0'}">
          <template #title>
            <div class="grid text-center">
              <h1 class="bold-600">{{$t("workspaces.setupWizard.WORKSPACE_SETUP.title")}}</h1>
            </div>
          </template>
          <template #content>
            <div class="p-grid p-text-center">
              <div class="p-col-12">
                <h4 class="p-text-light p-text-spaced">
                  {{$t('workspaces.setupWizard.WORKSPACE_SETUP.message1')}}
                  <!-- <br>
                  {{$t('workspaces.setupWizard.WORKSPACE_SETUP.message2')}} -->
                </h4>
              </div>
              <div class="p-col-12 p-text-center p-mt-4">
                <progress-spinner style="width:50px;height:50px" strokeWidth="2"/>
              </div>
            </div>
          </template>
      </Card>
    </div>
    <div v-if="stepAlias == setupWizardSteps.WORKSPACE_CONFIGURING">
      <Card :style="{'box-shadow': '0 0 0 0'}">
          <template #title>
            <div class="grid text-center">
              <h1 class="bold-600">{{$t("workspaces.setupWizard.WORKSPACE_CONFIGURING.title")}}</h1>
            </div>
          </template>
          <template #content>
            <div class="p-grid p-text-center">
              <div class="p-col-12">
                <h4 class="p-text-light p-text-spaced">
                  {{$t('workspaces.setupWizard.WORKSPACE_CONFIGURING.message1')}}<br>
                  {{$t('workspaces.setupWizard.WORKSPACE_CONFIGURING.message2')}}
                </h4>
              </div>
              <div class="p-col-12 p-text-center p-mt-4">
                <progress-spinner style="width:50px;height:50px" strokeWidth="2"/>
              </div>
            </div>
          </template>
      </Card>
    </div>
    <div v-if="stepAlias == setupWizardSteps.WORKSPACE_SETUP_COMPLETED">
      <Card :style="{'box-shadow': '0 0 0 0'}">
        <template #title>
            <div class="grid text-center">
              <h1 class="bold-600">{{$t("workspaces.setupWizard.WORKSPACE_SETUP_COMPLETED.title")}}</h1>
            </div>
          </template>
          <template #content>
            <div class="p-grid p-text-center">
              <div class="p-col-12">
                <h4 class="p-text-light p-text-spaced">
                  {{$t('workspaces.setupWizard.WORKSPACE_SETUP_COMPLETED.message')}}
                </h4>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="grid text-center">
              <div class="col-12">
                <Button
                  class="p-button-lg"
                  @click="step6_openWorkspace"
                  :label="$t('workspaces.setupWizard.WORKSPACE_SETUP_COMPLETED.callToAction')"
                />
              </div>
              <!-- <div class="col-12">
                <Button
                  class="p-button-link p-button-sm p-mt-2"
                  @click="step6_continueToAdminPanel"
                  :label="$t('workspaces.setupWizard.WORKSPACE_SETUP_COMPLETED.goToAdmin')"
                />
              </div> -->
            </div>
          </template>
      </Card>
    </div>
    <div v-if="stepAlias == setupWizardSteps.WORKSPACE_SETUP_ERROR">
      <Card :style="{'box-shadow': '0 0 0 0'}">
        <template #title>
            <div class="grid text-center">
              <i class="thin-alert p-icon-medium"></i>
              <h1 class="bold-600">{{$t("workspaces.setupWizard.WORKSPACE_SETUP_ERROR.title")}}</h1>
            </div>
          </template>
          <template #content>
            <div class="p-grid p-text-center">
              <div class="p-col-12">
                <h4 class="p-text-light p-text-spaced">
                  {{$t('workspaces.setupWizard.WORKSPACE_SETUP_ERROR.message')}}
                </h4>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="grid text-center">
              <div class="col-12">
                <Button
                  class="p-button-lg"
                  @click="step6_continueToAdminPanel"
                  :label="$t('workspaces.setupWizard.WORKSPACE_SETUP_ERROR.close')"
                />
              </div>
            </div>
          </template>
      </Card>
    </div>
  </Dialog>
</template>
<script>
import DialogMixin from "../../mixins/DialogMixin";
import Downloader from '../Downloader.vue';
import { mapState } from 'vuex';
import { wizardService } from '../../services/wizard.service';
import { adminService } from '../../services/admin.service';
import WizardSteps from '../workspaces/WizardSteps.vue';

const setupWizardSteps = Object.freeze({
  WELCOME: "WELCOME",
  DOWNLOAD: "DOWNLOAD",
  OPEN_INSTALLER: "OPEN_INSTALLER",
  LOGIN: "LOGIN",
  WORKSPACE_SETUP: "WORKSPACE_SETUP",
  WORKSPACE_CONFIGURING: "WORKSPACE_CONFIGURING",
  WORKSPACE_SETUP_COMPLETED: "WORKSPACE_SETUP_COMPLETED",
  WORKSPACE_SETUP_ERROR: "WORKSPACE_SETUP_ERROR"
});

const installerSteps = Object.freeze({
  CANCELED: "CANCELED",
  CONFIGURATION_ERROR: "CONFIGURATIONERROR",
  LOGIN_ERROR: "LOGINERROR",
  START: "START",
  REMOTE_START: "REMOTESTART",
  LOGGIN_IN: "LOGGININ",
  PROFILE_SELECTION: "PROFILESELECTION",
  SUBDOMAIN: "SUBDOMAIN",
  CONFIGURING: "CONFIGURING",
  FINISH: "FINISH",
  ERROR: "ERROR",
});

export const setupWizardModes = Object.freeze({
  FIRST_WORKSPACE_SETUP: "FIRST_WORKSPACE_SETUP",
  WORKSPACE_SETUP: "WORKSPACE_SETUP"
});

export default {
  name: "SetupWizard",
  tag: "setup-wizard",
  mixins: [DialogMixin],
  components: { Downloader, WizardSteps },
  props: {
    "mode": {
      "default": setupWizardSteps.FIRST_WORKSPACE_SETUP
    }
  },
  emits: [],
  data() {
    return {
      step: 1,
      stepsData: [{
        alias: setupWizardSteps.WELCOME,
      }, {
        alias: setupWizardSteps.DOWNLOAD
      }, {
        alias: setupWizardSteps.OPEN_INSTALLER
      }, {
        alias: setupWizardSteps.LOGIN
      }, {
        alias: setupWizardSteps.WORKSPACE_SETUP
      }, {
        alias: setupWizardSteps.WORKSPACE_CONFIGURING
      }, {
        alias: setupWizardSteps.WORKSPACE_SETUP_COMPLETED,
        workspaceUrl: "",
      }, {
        alias: setupWizardSteps.WORKSPACE_SETUP_ERROR,
      }],
      stepIndicator: 1,
      stepProgress: 0,
      stepIndicatorCompleted: false,
      showStepIndicator: true,
      pingMonitor: {
        tout: null,
        enabled: false,
      },
      waitForMonitor: {
        tout: null,
        controller: null,
        ongoing: false,
        enabled: false,
      },
      installerStep: null,
      installerStepData: null,
      $mode: this.mode,
      isWindows: navigator.userAgent.indexOf("Windows ") !== -1,
      setupWizardSteps: setupWizardSteps
    };
  },
  computed: {
    ...mapState({
      progressValue: (state) => state.installerDownload.progress,
      downloading: (state) => state.installerDownload.started,
      downloadCompleted: state => state.installerDownload.completed,
      downloadError: state => state.installerDownload.error,
      downloadErrorMsg: state => state.installerDownload.errorMsg,
    }),
    totalSteps() {
      return this.stepsData.length;
    },
    cStep() {
      return this.step - 1;
    },
    showNextButton() {
      let stepData = this.stepsData[this.cStep];
      return stepData?.nextBtn ?? false;
    },
    showBackButton() {
      let stepData = this.stepsData[this.cStep];
      return stepData?.backBtn ?? false;
    },
    stepAlias() {
      return this.stepsData[this.cStep]?.alias;
    }
  },
  mounted() {
    this.$store.state.installerDownload.allow = false;
    this.$store.state.installerDownload.background = true;
  },
  watch: {
    step: {
      immediate: true,
      handler: function(v) {
        if (this.internal.show) {
          this[`step${v}_mounted`]?.call(this, arguments);
          this[`step_${this.stepAlias}_mounted`]?.call(this, arguments);
        }
      }
    },
    progressValue (val) {
      if (this.step == 2) {
        this.stepProgress = val;
      }
    },
    downloadCompleted(val) {
      if (val) this.setStep("next");
    },
    installerStep(v) {
      switch(v) {
        case installerSteps.PROFILE_SELECTION:
          this.setStep(setupWizardSteps.WORKSPACE_SETUP);
          break;
        case installerSteps.CONFIGURING:
          this.setStep(setupWizardSteps.WORKSPACE_CONFIGURING);
          break;
        case installerSteps.FINISH:
          this.setStep(setupWizardSteps.WORKSPACE_SETUP_COMPLETED);
          break;
        case installerSteps.ERROR:
          this.setStep(setupWizardSteps.WORKSPACE_SETUP_ERROR);
          break;
        case installerSteps.CANCELED:
          this.emitResponse("completed");
          break;
      }
    }
  },
  methods: {
      next() {
        this.setStep("next");
      },
      back() {
        this.setStep("back");
      },
      show({ mode = setupWizardModes.WORKSPACE_SETUP } = {}) {
        this.$mode = mode;
        //this.startPingMonitor();
        this[`step${this.step}_mounted`]?.call(this, arguments);
        this[`step_${this.stepAlias}_mounted`]?.call(this, arguments);
        return DialogMixin.methods.show.call(this, arguments);
      },
      setStep(direction) {
        let newStep;
        if (['next', 'back'].includes(direction)) {
          let stepIncrement = direction == "next" ? 1 : direction == "back" ? -1 : 0;
          newStep = this.step + stepIncrement;
        } else {
          newStep = this.stepsData.findIndex(t => t.alias == direction) + 1;
        }

        this.step = newStep > 0 && newStep <= this.totalSteps ? newStep : this.step;
      },
      close() {
        this.emitResponse("closed");
      },
      reset() {
        this.setStep(setupWizardSteps.WELCOME);
        this.installerStep = null;
      },
      setupHidden() {
        this.reset();
        this.stopPingMonitor();
        this.stopWaitForMonitor();
      },
      startPingMonitor() {
        this.pingMonitor.enabled = true;
        this.doPingMonitor();
      },
      stopPingMonitor() {
        this.pingMonitor.enabled = false;
        clearInterval(this.pingMonitor.tout);
        this.pingMonitor.tout = null;
      },
      doPingMonitor() {
        const time = 2000;

        clearInterval(this.pingMonitor.tout);
        this.pingMonitor.tout = null;

        wizardService.ping().then(({ _data }) => {
          this.setStep(setupWizardSteps.LOGIN);
        }).catch(() => {
        }).then(() => {
          if (this.pingMonitor.enabled)
            this.pingMonitor.tout = setTimeout(() => this.doPingMonitor(), time);
        });
      },
      startWaitForMonitor() {
        this.waitForMonitor.enabled = true;

        if (!this.waitForMonitor.ongoing)
          this.doWaitForMonitor();
      },
      stopWaitForMonitor() {
        this.waitForMonitor.enabled = false;
        clearInterval(this.waitForMonitor.tout);
        this.waitForMonitor.tout = null;
        try {
          this.waitForMonitor.controller?.abort();
        } catch { /* error while trying to abort waitfor current call */ }
      },
      doWaitForMonitor() {
        const time = 0;

        clearInterval(this.waitForMonitor.tout);
        this.waitForMonitor.tout = null;

        if (!this.waitForMonitor.enabled) {
          return;
        }

        this.waitForMonitor.controller = new AbortController();
        this.waitForMonitor.ongoing = true;
        wizardService.waitFor({ signal: this.waitForMonitor.controller.signal }).then(({ data }) => {
          this.installerStepData = data;
          this.installerStep = data?.stateText?.toUpperCase() ?? null;
        }).catch(() => {
          this.installerStepData = null;
          this.installerStep = installerSteps.ERROR;
        }).then(() => {
          this.waitForMonitor.ongoing = false;
          this.installerStepData = null;
          this.waitForMonitor.controller = null;
          if (this.waitForMonitor.enabled) {
            this.waitForMonitor.tout = setTimeout(() => this.doWaitForMonitor(), time);
          }
        });
      },
      setStepIndicator(step, completed, progress = 0) {
        if (step === false) {
          this.showStepIndicator = false;
          return;
        }

        this.showStepIndicator = true;
        this.stepProgress = progress;
        this.stepIndicator = step;
        this.stepIndicatorCompleted = completed ?? false;
      },
      step_WELCOME_mounted() {
        this.setStepIndicator(1, false);
        this.startPingMonitor();
      },
      step1_downloadInstaller() {
        this.setStep(setupWizardSteps.DOWNLOAD);
      },
      step1_tryLater() {
        this.emitResponse("dismissed");
      },
      step_DOWNLOAD_mounted() {
        this.setStepIndicator(1, true);
        this.$store.dispatch("downloadInstaller");
      },
      step_OPEN_INSTALLER_mounted() {
        this.setStepIndicator(1, true, 100);
        this.installerStep = null;

        this.startPingMonitor();
      },
      step4_tryLogin(otpl, retries, every, retry = 1, res, rej) {
        let p;
        if (!res) {
          p = new Promise((_res, _rej) => {
            res = _res;
            rej = _rej;
          });
        }

        wizardService.login({ otpl: otpl }).then(({ data }) => {
          res(data);
        }).catch(() => {
          if (retry <= retries)
            setTimeout(() => this.step4_tryLogin(otpl, retries, every, ++retry, res, rej), every);
          else
            rej();
        });

        return p;
      },
      step4_sendStart() {
        wizardService.start().then(({ data }) => {
          if (data?.stateText?.toUpperCase() === installerSteps.CANCELED) {
            this.emitResponse("completed");
            return;
          }

          adminService.getOneTimeUrl(false, { config: { ajaxOptions: { ajaxState: false } } }).then(({ data: _data = {} }) => {
            _data = _data?.data ?? {};
            if (_data?.uri) {
              this.step4_tryLogin(_data?.uri, 3, 1000).then((data) => {
                if (data?.stateText?.toUpperCase() === installerSteps.CANCELED) {
                  this.emitResponse("completed");
                  return;
                }

                this.startWaitForMonitor();
              }).catch(() => {
                this.setStep(setupWizardSteps.WORKSPACE_SETUP_ERROR);
              });
            } else {
              this.setStep(setupWizardSteps.WORKSPACE_SETUP_ERROR);
            }
          });
        }).catch(() => {
          this.setStep(setupWizardSteps.WORKSPACE_SETUP_ERROR);
        });
      },
      step_LOGIN_mounted() {
        this.stopPingMonitor();
        this.setStepIndicator(2, false);
        this.step4_sendStart();
      },
      step_WORKSPACE_SETUP_mounted() {
        this.setStepIndicator(2, true);
      },
      step_WORKSPACE_CONFIGURING_progress(etime, every) {
        if (this.stepAlias == setupWizardSteps.WORKSPACE_CONFIGURING) {
          const offset = 15;
          const prog = this.stepProgress - offset < 0 ? 0 : this.stepProgress - offset;
          const sp = (prog + ((100-prog) / (etime / every))) + offset;
          this.stepProgress = sp <= 100 ? sp : 100;
          setTimeout(() => this.step_WORKSPACE_CONFIGURING_progress(etime, every), every);
        }
      },
      step_WORKSPACE_CONFIGURING_mounted() {
        this.setStepIndicator(2, true, 0);
        this.step_WORKSPACE_CONFIGURING_progress(30 * 1000, 500);
      },
      step_WORKSPACE_SETUP_COMPLETED_mounted() {
        this.setStepIndicator(3, true);
        this.stepsData.find(t => t.alias == setupWizardSteps.WORKSPACE_SETUP_COMPLETED).workspaceUrl = this.installerStepData.service?.url;
        this.stopWaitForMonitor();
      },
      async step6_openWorkspace() {
        let wurl = this.stepsData.find(t => t.alias == setupWizardSteps.WORKSPACE_SETUP_COMPLETED).workspaceUrl;
        await adminService.getOneTimeUrl(true, { config: { ajaxOptions: { ajaxState: true } } })
          .then(({ data }) => {
          data = data?.data;
          window.open(wurl + (data?.uri ?? ""), "_blank", "noopener");
        });
        this.emitResponse("completed");
      },
      step6_continueToAdminPanel() {
        this.emitResponse("completed");
      },
      step_WORKSPACE_SETUP_ERROR_mounted() {
        this.stopPingMonitor();
        this.stopWaitForMonitor();
        this.setStepIndicator(false);
      }
  },
};
</script>
<style scoped>
.bold-600 {
  font-weight: 600;
}
.p-text-spaced {
  line-height: 1.6em;
}
.p-color-gray {
  color: var(--gray) !important;
  text-decoration: underline !important;
}
.p-icon-medium {
  color: var(--thin-blue);
  font-size: 3em;
}
</style>