import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import AdminLayout from "../layouts/AdminLayout";
import Users from "../pages/Users";
import Account from "../pages/Account";
import Plans from "../pages/Plans";
import Workspaces from "../pages/Workspaces";
import { store } from "../store";
import roles, { hasPermissions } from '../enums/roles';
import { apiConfig } from '../config/backend-api';
import { trackRouter } from "vue-gtag-next";

export const config = {
    defaultRoute: { name: 'workspaces' }
};

const ifLoggedThenDefaultRoute = (to, from, next) => {
    if (store.state.auth.logged) {
        next(config.defaultRoute);
    } else {
        next(true);
    }
};

export const routes = [
    {
        path: "/home",
        meta: { requiresAuth: false, onlyAdmin: false },
        redirect: '/login',
    },
    {
        path: "/",
        name: "home",
        meta: { requiresAuth: false, onlyAdmin: false },
        /*redirect: '/login',*/
        component: () => import("../pages/Home.vue"),
        beforeEnter: ifLoggedThenDefaultRoute,
    },
    {
        path: "/login",
        name: "login",
        meta: { requiresAuth: false, onlyAdmin: false },
        component: () => import("../pages/Login.vue"),
        beforeEnter: ifLoggedThenDefaultRoute,
    },
    {
        path: "/register",
        name: "register",
        meta: { requiresAuth: false, onlyAdmin: false },
        component: () => import("../pages/Register.vue"),
        beforeEnter: ifLoggedThenDefaultRoute,
    },
    {
        path: "/otpl/:usertoken(.*)",
        name: "otpl",
        meta: { requiresAuth: false, onlyAdmin: false },
        component: () => import("../pages/otpl.vue"),
    },
    {
        path: "/forgotpassword",
        name: "forgotpassword",
        meta: { requiresAuth: false, onlyAdmin: true },
        component: () => import("../pages/ForgotPassword.vue"),
    },
    {
        path: "/resetpassword/:usertoken",
        name: "resetpassword",
        meta: { requiresAuth: false, onlyAdmin: true },
        component: () => import("../pages/ResetPassword.vue"),
    },
    {
        path: "/changepassword",
        name: "changepassword",
        meta: { requiresAuth: false, onlyAdmin: false },
        component: () => import("../pages/ChangePassword.vue"),
    },
    {
        path: "/emailconfirmation/:usertoken",
        meta: { requiresAuth: false, onlyAdmin: true },
        component: () => import("../pages/EmailConfirmation.vue"),
    },
    {
        path: "/dzone",
        meta: { requiresAuth: false, onlyAdmin: true },
        component: () => import("../pages/dzone.vue"),
    },
    {
        path: "/networkerror",
        meta: { requiresAuth: false, onlyAdmin: true },
        component: () => import("../pages/NetworkNotAvailable.vue"),
    },
    {
        path: "/admin",
        meta: { requiresAuth: true, onlyAdmin: true },
        component: AdminLayout,
        children: [
            {
                path: "workspaces",
                name: "workspaces",
                meta: { trialNotExpired: true },
                component: Workspaces,
                props: () => ({ readOnly: !hasPermissions(store.state.auth.user.role, roles.ADMIN) })
            },
            {
                path: "users",
                name: "users",
                meta: { trialNotExpired: true, minRole: roles.ADMIN },
                component: Users,
            },
            {
                path: 'account',
                name: 'account',
                meta: { trialNotExpired: true, minRole: roles.OWNER },
                component: Account,
            },
            {
                path: 'plans',
                name: 'plans',
                meta: { trialNotExpired: true, minRole: roles.OWNER },
                component: Plans,
            },
        ],
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        meta: { requiresAuth: false },
        component: () => import("../pages/ErrorPage.vue"),
    },
];

const router = createRouter({
    history: (process.env.VUE_APP_USE_WEB_HISTORY.toUpperCase() == "TRUE") ? createWebHistory() : createWebHashHistory(),
    relative: true,
    routes,
});

const permissions = ['requiresAuth', 'trialNotExpired', 'onlyAdmin', 'minRole']

const resolvePermissions = (_route) => {
    const meta = _route.meta ?? {};
    const filtered = Object.keys(meta).reduce((acc, key) => {
        if (permissions.includes(key)) {
            acc[key] = meta[key];
        }
        return acc;
    }, {});

    const deny = Object.entries(filtered).reduce((_deny, [_key, _value]) => {
        switch (_key) {
            case 'minRole':
                _deny = _deny || !hasPermissions(store.state.auth.user.role, _value);
                break;
        }

        return _deny;
    }, false);

    return !deny;
}

router.beforeEach((to, from, next) => {
    if (to.fullPath == '/networkerror' || store.state.networkError) {
        if (store.state.networkError) {
            next((to.fullPath == "/networkerror") ? true : "/networkerror");
        } else {
            window.location.href = window.location.origin;
        }
    } else if (apiConfig.ipAllowed === false) {
        next((to.fullPath == "/dzone") ? true : "/dzone");
    } else if (to.meta.requiresAuth && !store.state.auth.logged) {
        next("/login");
    } else {
        let allowed = resolvePermissions(to);
        next(allowed ? true : from.path);
    }
});

trackRouter(router);

export default router;
