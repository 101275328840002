'use strict';
import { userRole, userStatus } from '../services/auth.service';

export default class UserHelper {
    roles = [];
    status = [];

    constructor(dicc) {
        this.roles = [
                { id: userRole.USER, name: dicc('users.roleNameUser') },
                { id: userRole.ADMIN, name: dicc('users.roleNameAdmin') },
                { id: userRole.OWNER, name: dicc('users.roleNameOwner') },
            ];
        this.status = [
                { id: userStatus.PENDING, name: dicc('users.statusPending') },
                { id: userStatus.ACTIVE, name: dicc('users.statusActive') },
                { id: userStatus.INACTIVE, name: dicc('users.statusInactive') },
                { id: userStatus.LOCKED, name: dicc('users.statusLocked') },
            ];
    }

    roleName(roleLevel) {
        return this.roles.find(element => element.id === roleLevel)?.name;
    }

    statusName(status) {
        return this.status.find(element => element.id === status)?.name;
    }

    isOwner(roleLevel) {
        return roleLevel == userRole.OWNER
    }  

    isCommonUser(roleLevel) {
        return roleLevel == userRole.USER
    }
}