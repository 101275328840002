<template>
  <Password :maxLength="passwordComplexity.maxlen">
    <template #content>
      <Divider />
      <div class="requisites">
        <p>{{ $t('register.passwordRequirements') }}</p>
        <ul class="p-ml-2 p-ml-2 p-mt-0">
          <li>
            {{ $t('passwordComplexity.minMaxLen', { min: this.passwordComplexity.minlen, max: this.passwordComplexity.maxlen }) }}
          </li>
          <li v-if="this.passwordComplexity.mustContainUppercase">
            {{ $t('passwordComplexity.mustContainUppercase') }}
          </li>
          <li v-if="this.passwordComplexity.mustContainLowercase">
            {{ $t('passwordComplexity.mustContainLowercase') }}
          </li>
          <li v-if="this.passwordComplexity.mustContainNumbers">
            {{ $t('passwordComplexity.mustContainNumbers') }}
          </li>
          <li v-if="this.passwordComplexity.mustContainNonAlphanumeric">
            {{ $t('passwordComplexity.mustContainNonAlphanumeric') }}
          </li>
        </ul>
      </div>
    </template>
  </Password>
</template>
<script>
export default {
  name: 'PasswordComplexity',
  data() {
      return {
        passwordComplexity : {},
    }
  },
  mounted() {
    this.passwordComplexity = this.$store.state.passwordComplexity;
  }

};
</script>
<style lang="css" scoped>
.requisites {
  font-size: .8rem;
}
</style>