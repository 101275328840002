<template>
    <div class="plan-card" :class="{active: isActive, 'only-checks': onlyChecks, 'only-available': !onlyChecks, 'indy': indy, 'best-choice': pInfo.bestChoice, 'preselected': preselected }"  @mouseenter="onEnter" @click="selectPlan" @mouseleave="onLeave">
        <div class="header" :class="{ 'with-price': pricePlace.toLowerCase() != 'footer' }">
            <h2>{{pInfo.title}}</h2>
            <h4 v-for="item in pInfo.description" :key="item">{{item}}</h4>
            <div class="price" v-if="pricePlace.toLowerCase() != 'footer' || !onlyChecks">
                <h1>{{currencySymbol}}{{dollars}}<span class="super">{{cents}}</span></h1>
                <span>{{$t("plans.per_concurrent")}}</span>
                <span>{{payingPeriod}}</span>
            </div>
        </div>
        <div class="features">
            <div class="feature" :class="{'p-text-center': onlyChecks}" v-for="(item, index) in ranges" :key="index"><b class="range">{{item}}</b></div>
            <div class="feature" :class="{'p-text-center': onlyChecks}"><b class="range">{{pSupport}}</b></div>
            <div class="feature" v-if="pAllIn && !onlyChecks"><b>{{pAllIn}}:</b></div>
            <div class="feature" :class="{'p-text-center': onlyChecks}" v-for="feat in featureList" :key="feat" v-tooltip.top="feat.help">
                <span v-if="onlyChecks" class="icon" :class="{'thin-check': feat.available, 'thin-cancel': !feat.available }"></span>
                <span v-else :class="{'enabled':feat.available, 'wrapped': !onlyChecks, 'thin-check': !pAllIn, 'thin-rounded-plus': pAllIn }">{{feat.desc}}</span>
            </div>
            <div class="feature" v-for="n in emptyLines" :key="n">
                <span>&nbsp;</span>
            </div>
        </div>
        <div class="footer">
            <div class="price" v-if="pricePlace.toLowerCase() != 'header' && onlyChecks">
                <h1>{{currencySymbol}}{{dollars}}<span class="super">{{cents}}</span></h1>
                <span>{{$t("plans.per_concurrent")}}</span>
                <span>{{payingPeriod}}</span>
            </div>
            <div v-if="actionButtons" class="action" @click="purchasePlan">select</div>
            <div v-else class="p-my-0">&nbsp;</div>
        </div>
    </div>
</template>
<script>
import PlancardHelper from "../../helpers/PlancardHelper";

export default {
    name: "PlanCard",
    tag: "plan-card",
    props: {
        "plan": { "default": 1 } ,
        'indy': { "default": false },
        'selectable': { "default": true },
        'onlyChecks': { "default": true },
        'monthly': { "default": true },
        'currencySymbol': { "default": "$" },
        'quiet': { "default": true },
        'actionButtons': { "default": true },
        'pricePlace': { "default": "header"}
    },
    emits: ["select", "purchase"],
    data() {
        return {
            isActive: false,
            pInfo: PlancardHelper.getPlan(this.plan, this.$t),
            pFeatures: PlancardHelper.getFeatures(this.plan, this.$t),
            pOwnFeatures: PlancardHelper.getOwnFeatures(this.plan, this.$t),
            pSupport: PlancardHelper.getSupport(this.plan, this.$t),
            pAllIn: PlancardHelper.getAllIn(this.plan, this.$t)
        }
    },
    computed: {
        dollars() { return Math.trunc(PlancardHelper.getPrice(this.plan, this.monthly)) },
        cents() { return ("0" + Math.trunc(PlancardHelper.getPrice(this.plan, this.monthly) * 100)).substr(-2) },
        payingPeriod() { return (this.monthly) ? this.$t("plans.period.monthly") : (this.$t("plans.period.perMonth") + ", " + this.$t("plans.period.yearly"))},
        preselected() { return this.quiet && this.pInfo?.bestChoice },
        ranges() { return PlancardHelper.getRanges(this.plan, this.$t, this.onlyChecks) },
        featureList() { return (this.onlyChecks) ? this.pFeatures : this.pOwnFeatures },
        emptyLines() { return (this.onlyChecks || this.indy) ? 0 : (PlancardHelper.getMaxOwnFeatures() - Object.keys(this.featureList).length - ((this.pAllIn) ? 1 : 0)) }
    },
    methods: {
        selectPlan: function() { if (this.selectable) this.$emit("select", this.plan) },
        purchasePlan: function() { if (this.selectable) this.$emit("purchase", this.plan) },
        onEnter: function() { this.changeState(true); },
        onLeave: function() { this.changeState(false); },
        changeState: function(value) { this.isActive = value; }
    }
}
</script>