'use strict';
export default class ClipboardHelper {
    static #clipboardElem = document.querySelector("#clipboardElem");

    static createElem() {
        this.#clipboardElem = document.createElement("textArea");
        this.#clipboardElem.id = "clipboardElem";
        this.#clipboardElem.style.position = "absolute";
        this.#clipboardElem.style.top = "0px";
        this.#clipboardElem.style.width = "2px";
        this.#clipboardElem.style.height = "2px";
        document.body.appendChild(this.#clipboardElem);
    }

    static #tryWithRange() {
        let range = document.createRange();
        range.selectNodeContents(this.#clipboardElem);
        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        this.#clipboardElem(0, this.#clipboardElem.value.length);
        return document.execCommand("copy");
    }

    static copyText(value) {
        let result = false;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(value);
            result = true;
        } else {
            if (!this.#clipboardElem) this.createElem();
            this.#clipboardElem.innerHTML = value;
            this.#clipboardElem.focus();
            this.#clipboardElem.select();
            try {
                result = document.execCommand('copy');
                if (!result)
                    result = this.#tryWithRange();
            } catch(err) {
                console.log('Oops, unable to copy');
            }
            window.getSelection().removeAllRanges();
        }
        return result;
    }
}